import { forwardRef, useRef } from 'react'
import { ButtonProps } from './interfaces'
import styles from './Button.module.scss'
import mergeRefs from 'react-merge-refs'
import cn from 'classnames'

const Button = forwardRef((props: ButtonProps, buttonRef) => {
  const {
    component: Component = 'button',
    variant = 'default',
    color = 'default',
    size = 'default',
    loading = false,
    disabled = false,
    children,
    ...rest
  } = props
  const ref = useRef<typeof Component>(null)

  return (
    <Component
      ref={mergeRefs([ref, buttonRef])}
      className={cn(styles.button, {
        [styles.disabled]: disabled,
        [styles.primary]: variant === 'primary',
        [styles.decor]: color === 'decor',
        [styles.navigation]: color === 'navigation',
        [styles.large]: size === 'large',
      })}
      disabled={disabled}
      {...rest}
    >
      <span>{children}</span>
    </Component>
  )
})

export default Button
