import { IconProps } from './interfaces'

const SVG = ({ fill = 'none', width = '16', height = '17', viewBox = '0 0 16 17' }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.31516 6.75109C9.31516 7.37109 8.89516 7.67109 8.42516 8.00109C7.80516 8.43109 7.37516 8.75109 7.37516 9.66109V9.92109H8.21516V9.71109C8.21516 9.04109 8.60516 8.85109 9.09516 8.50109C9.61516 8.13109 10.2152 7.74109 10.2152 6.71109C10.2152 5.67109 9.39516 4.87109 8.07516 4.87109C6.76516 4.87109 5.95516 5.69109 5.78516 6.72109H6.65516C6.83516 6.13109 7.23516 5.67109 8.06516 5.67109C8.81516 5.67109 9.31516 6.11109 9.31516 6.75109ZM7.82516 10.9111C7.48516 10.9111 7.20516 11.1711 7.20516 11.5211C7.20516 11.8711 7.48516 12.1311 7.82516 12.1311C8.16516 12.1311 8.44516 11.8711 8.44516 11.5211C8.44516 11.1711 8.16516 10.9111 7.82516 10.9111Z"
      fill="#989EB4"
    />
    <circle cx="8" cy="8.5" r="7.5" stroke="#BBBFD1" />
  </svg>
)

export default SVG
