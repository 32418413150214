import { forwardRef, useRef } from 'react'
import mergeRefs from 'react-merge-refs'

const base64Pixel =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='

const Picture = forwardRef((props: PictureProps, imageRef) => {
  const { noImageOnTouch = false, alt = '' } = props

  const desktopImages = props.desktop || {}
  const {
    x1: desktop_x1,
    x2: desktop_x2,
    webp_x1: desktop_webp_x1,
    webp_x2: desktop_webp_x2,
  } = desktopImages

  const tabletImages = props.tablet || {}
  const {
    x1: tablet_x1,
    x2: tablet_x2,
    webp_x1: tablet_webp_x1,
    webp_x2: tablet_webp_x2,
  } = tabletImages

  const mobileImages = props.mobile || {}
  const {
    x1: mobile_x1,
    x2: mobile_x2,
    webp_x1: mobile_webp_x1,
    webp_x2: mobile_webp_x2,
  } = mobileImages

  const { onLoad, onLoadError } = props

  const ref = useRef<HTMLImageElement>(null)

  return desktop_x1 && desktop_x1.endsWith('.svg') ? (
    <img ref={mergeRefs([ref, imageRef])} src={desktop_x1} alt="" />
  ) : (
    <picture>
      {noImageOnTouch && (
        <source
          media="(hover: none) and (pointer: coarse), (hover: none) and (pointer: fine)"
          srcSet={base64Pixel}
          sizes="100%"
        />
      )}
      <source
        type="image/webp"
        media={`(min-width: 1025px)`}
        srcSet={`${desktop_webp_x1}, ${desktop_webp_x2} 2x`}
      />
      <source media={`(min-width: 1025px)`} srcSet={`${desktop_x1}, ${desktop_x2} 2x`} />
      <source
        type="image/webp"
        media={`(min-width: 501px)`}
        srcSet={`${tablet_webp_x1}, ${tablet_webp_x2} 2x`}
      />
      <source media={`(min-width: 501px)`} srcSet={`${tablet_x1}, ${tablet_x2} 2x`} />

      <source
        type="image/webp"
        media={`(max-width: 500px)`}
        srcSet={`${mobile_webp_x1}, ${mobile_webp_x2} 2x`}
      />
      <source media={`(max-width: 500px)`} srcSet={`${mobile_x1}, ${mobile_x2} 2x`} />

      <img
        ref={mergeRefs([ref, imageRef])}
        src={desktop_x1}
        srcSet={`${desktop_x2} 2x`}
        alt={alt}
        onLoad={onLoad}
        onError={onLoadError}
      />
    </picture>
  )
})

export default Picture

export interface Picture {
  desktop: PictureSources
  tablet: PictureSources
  mobile: PictureSources
}

export interface PictureProps extends Picture {
  alt?: string
  noImageOnTouch?: boolean
  onLoad?: (e: any) => void
  onLoadError?: (e: any) => void
}

type PictureSources = {
  x1: string
  x2: string
  webp_x1: string
  webp_x2: string
}
