import { Title, Paragraph, Button, LazyPicture } from '@/components'
import getLinkHref from '@/helpers/getLinkHref'
import styles from './Registration.module.scss'
import Link from 'next/link'

export default function Registration() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Title>Are you a professional installer?</Title>
        <Paragraph>Get new leads and clients for free!</Paragraph>

        <Link href={getLinkHref({ type: 'registration' })} passHref>
          <Button component="a" variant="primary" size="large">
            Know More
          </Button>
        </Link>
      </div>

      <div className={styles.img}>
        <LazyPicture
          data={{
            desktop: {
              x1: '/images/footer-reg/img-d.png',
              x2: '/images/footer-reg/img-d.png',
              webp_x1: '/images/footer-reg/img-d.webp',
              webp_x2: '/images/footer-reg/img-d.webp',
            },
            tablet: {
              x1: '/images/footer-reg/img-d.png',
              x2: '/images/footer-reg/img-d.png',
              webp_x1: '/images/footer-reg/img-d.webp',
              webp_x2: '/images/footer-reg/img-d.webp',
            },
            mobile: {
              x1: '/images/footer-reg/img-m.png',
              x2: '/images/footer-reg/img-m.png',
              webp_x1: '/images/footer-reg/img-m.webp',
              webp_x2: '/images/footer-reg/img-m.webp',
            },
          }}
        />
      </div>
    </div>
  )
}
