import { GalleryWrapperProps } from './interfaces'
import { useState, useCallback } from 'react'
import Gallery from './Gallery'

export default function GalleryWrapper({
  name,
  description,
  images,
  initialSlide = 0,
  children,
  ...props
}: GalleryWrapperProps) {
  const [modalVisible, setModalVisible] = useState(false)

  const onActionClick = useCallback(() => {
    setModalVisible(true)
  }, [])

  const onModalClose = useCallback(() => {
    setModalVisible(false)
  }, [])

  return (
    <>
      <div {...props} onClick={onActionClick}>
        {children}
      </div>

      <Gallery
        name={name}
        description={description}
        visible={modalVisible}
        onClose={onModalClose}
        initialIndex={initialSlide}
        closableOverlay={false}
        images={images}
      />
    </>
  )
}
