import { ClosestByZip, Container, Title, Text, LazyPicture } from '@/components'
import styles from './Promo.module.scss'

export default function Promo() {
  return (
    <Container size="wide" className={styles.container}>
      <div className={styles.promo}>
        <div className={styles.content}>
          <Title>
            Our service will help you find <Text mark>professional installers</Text> of prefinished
            doors
          </Title>

          <div className={styles.text}>Find the installer for free:</div>

          <ClosestByZip btnColor="decor" className={styles.search} />
        </div>
        <div className={styles.img}>
          <LazyPicture
            data={{
              desktop: {
                x1: '/images/promo/img-d.png',
                x2: '/images/promo/img-d.png',
                webp_x1: '/images/promo/img-d.webp',
                webp_x2: '/images/promo/img-d.webp',
              },
              tablet: {
                x1: '/images/promo/img-d.png',
                x2: '/images/promo/img-d.png',
                webp_x1: '/images/promo/img-d.webp',
                webp_x2: '/images/promo/img-d.webp',
              },
              mobile: {
                x1: '/images/promo/img-m.png',
                x2: '/images/promo/img-m.png',
                webp_x1: '/images/promo/img-m.webp',
                webp_x2: '/images/promo/img-m.webp',
              },
            }}
          />
        </div>
      </div>
    </Container>
  )
}
