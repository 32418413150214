import { useEffect, useRef, useState, DragEventHandler } from 'react'
import { DragAndDropProps } from './interfaces'

export default function DragAndDrop({
  component: Component,
  onDrop,
  onClick,
  draggingClassName,
  children,
}: DragAndDropProps) {
  const rootRef = useRef<HTMLElement | null>(null)
  const [state, setState] = useState({ dropDepth: 0, inDropZone: false })

  useEffect(() => {
    document.addEventListener('dragover', onDocumentDragOver, false)
    document.addEventListener('drop', onDocumentDrop, false)

    return () => {
      document.removeEventListener('dragover', onDocumentDragOver)
      document.removeEventListener('drop', onDocumentDrop)
    }
  }, [])

  const onDocumentDragOver = (e: DragEvent) => {
    e.preventDefault()
  }

  const onDocumentDrop = (e: DragEvent) => {
    const target = e.target as Node
    if (rootRef.current && target && rootRef.current.contains(target)) {
      return
    }
    e.preventDefault()
  }

  const handleDragEnter: DragEventHandler = e => {
    e.preventDefault()
    e.stopPropagation()

    setState(prev => ({
      ...prev,
      dropDepth: prev.dropDepth + 1,
    }))
  }

  const handleDragLeave: DragEventHandler = e => {
    e.preventDefault()
    e.stopPropagation()

    setState(prev => ({
      ...prev,
      dropDepth: prev.dropDepth - 1,
      inDropZone: prev.dropDepth === 1 ? false : true,
    }))
  }

  const handleDragOver: DragEventHandler = e => {
    e.preventDefault()
    e.stopPropagation()

    e.dataTransfer.dropEffect = 'copy'
    setState(prev => ({
      ...prev,
      inDropZone: true,
    }))
  }

  const handleDrop = (e: DragEvent & { dataTransfer?: DataTransfer }) => {
    e.preventDefault()
    e.stopPropagation()

    let files = Array.from(e.dataTransfer.files)

    if (files && files.length > 0) {
      onDrop(files)
      setState(prev => ({
        ...prev,
        dropDepth: 0,
        inDropZone: false,
      }))
    }
  }

  return (
    <Component
      ref={rootRef}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onClick={onClick}
      {...(state.inDropZone && { className: draggingClassName || 'dragging' })}
    >
      {children}
    </Component>
  )
}
