import { changeSearchField, getSearchForm, HowMany } from '@/store/slices/registrationSlice'
import { useSelector, useDispatch } from 'react-redux'
import { Title, Text, Radio } from '@/components'
import styles from '../SearchPage.module.scss'

const filterName = 'how_many'
const radio: { name: HowMany; title: string }[] = [
  { name: '1', title: '1' },
  { name: '2', title: '2' },
  { name: '3', title: '3' },
  { name: '4 or more', title: '4 or more' },
]

export default function StepThree() {
  const dispatch = useDispatch()

  const { data } = useSelector(getSearchForm)
  const value = data[filterName]

  const onFieldChange = (value: HowMany) => {
    dispatch(changeSearchField({ fieldName: filterName, fieldValue: value }))
  }

  return (
    <div className={styles.content}>
      <Title size="medium" align="center">
        How many <Text mark>doors</Text> would you like installed?
      </Title>

      <div className={styles.options}>
        {radio.map(item => {
          const { name, title } = item

          return (
            <Radio
              key={name}
              name={filterName}
              value={name}
              checked={value === name}
              title={title}
              onChange={onFieldChange}
            />
          )
        })}
      </div>
    </div>
  )
}
