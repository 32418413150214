import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '44',
  height = '49',
  viewBox = '0 0 44 49',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M27.703 28.387H14.465a.742.742 0 000 1.483h13.238a.742.742 0 000-1.483zM17.563 10.14H6.349a.742.742 0 000 1.483h11.214a.742.742 0 00.741-.741.73.73 0 00-.741-.742zM27.703 22.305H6.35a.742.742 0 000 1.482h21.354a.742.742 0 000-1.482zM27.703 16.223H6.35a.742.742 0 000 1.482h21.354a.742.742 0 000-1.482zM20.595 36.722a.748.748 0 00-1.055 0c-1.483 1.454-3.384 3.126-4.144 3.64l-.266.17-.133-.304c-.19-.418-.4-1.121-.513-1.501v-.01c-.409-1.35-.675-2.242-1.511-2.242-.723 0-1.074.712-1.654 1.89l-.019.039c-.694 1.406-.998 1.938-1.207 2.081l-.076.057-.095-.019c-.352-.038-.827-.418-1.169-.732l-.171-.17.152-.19c1.245-1.607 1.958-3.156 1.958-4.24 0-2.014-1.701-2.775-2.775-2.775-.523 0-1.217.419-1.768 1.065-.656.798-1.017 1.768-1.017 2.746 0 .903.485 2.091 1.321 3.25l.133.19-.17.172a8.872 8.872 0 01-.999.874.745.745 0 00-.152 1.045c.143.19.361.295.599.295a.707.707 0 00.447-.152c.342-.257.703-.57 1.074-.94l.17-.172.19.171c.799.713 1.569 1.103 2.167 1.103 1.15 0 1.882-1.283 2.652-2.842l.304-.608.19.646c.437 1.445.846 2.804 1.92 2.804.142 0 .428 0 1.216-.504 1.036-.656 2.51-1.93 4.372-3.764.314-.313.323-.779.029-1.073zM7.697 38.337l-.114.162-.209-.323c-.342-.523-.75-1.283-.75-1.949 0-1.273.902-2.1 1.14-2.252l.057-.038h.076c.636 0 1.283.4 1.283 1.283 0 .665-.513 1.777-1.378 2.965l-.105.152zM34.64 37.739l1.56 1.558 3.458-3.46 1.198 1.198-4.619 4.61-.057.028-2.746-2.746 1.207-1.188zm-4.314-5.93h-.627v6.756c0 2.29.788 4.534 2.214 6.32l.18.228.096.114.874.875.104.085.2.171a10.04 10.04 0 003.24 1.797l.2.066.2-.066a10.254 10.254 0 005.027-3.66 9.929 9.929 0 001.958-5.93v-6.757h-.618c-1.634 0-3.126-.313-4.457-.93-1.017-.476-1.568-.96-1.663-1.056l-.428-.427-.437.437c-.01.01-1.501 1.425-4.362 1.853l-.219.028s-.237.029-.589.048l-.893.047z" />
    <path d="M0 44.334a3.786 3.786 0 003.782 3.782H30.27a3.786 3.786 0 003.782-3.782v-1.492H32.56v1.492c0 1.264-1.026 2.3-2.3 2.3H3.782a2.302 2.302 0 01-2.3-2.3V3.792c0-1.264 1.027-2.3 2.3-2.3H22.42V7.84a3.786 3.786 0 003.782 3.783h6.349v24.395h1.492V10.882a.71.71 0 00-.219-.523L23.702.219A.726.726 0 0023.179 0H3.792A3.796 3.796 0 000 3.792v40.542zM23.92 2.547l7.594 7.593H26.22a2.302 2.302 0 01-2.3-2.3V2.547z" />
  </svg>
)

export default SVG
