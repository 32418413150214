export const YM_TRACKING_ID = '73932394'

const isProductionMode = process.env.NODE_ENV === 'production'

// https://yandex.ru/support/metrica/objects/hit.html
const pageview = url => {
  try {
    if (isProductionMode) {
      window.ym(YM_TRACKING_ID, 'hit', url)
    }
  } catch (error) {}
}

const trackGoal = goal => params => {
  try {
    if (isProductionMode) {
      ym(`${YM_TRACKING_ID}`, 'reachGoal', goal, params)
    }
  } catch (e) {
    console.error('YM trackGoal exception', e)
  }
}

export default {
  pageview,
  registration: trackGoal('registration'),
  request: trackGoal('request'),
}
