import { changeSearchField, getSearchForm, Location } from '@/store/slices/registrationSlice'
import { useSelector, useDispatch } from 'react-redux'
import { Title, Text, RadioIcon } from '@/components'
import styles from '../SearchPage.module.scss'

const filterName = 'location'
const radio: { name: Location; title: string }[] = [
  { name: 'home', title: 'Home / Residence' },
  { name: 'business', title: 'Business' },
]

export default function StepTwo() {
  const dispatch = useDispatch()

  const { data } = useSelector(getSearchForm)
  const value = data[filterName]

  const onFieldChange = (value: Location) => {
    dispatch(changeSearchField({ fieldName: filterName, fieldValue: value }))
  }

  return (
    <div className={styles.content}>
      <Title size="medium" align="center">
        What kind of <Text mark>location</Text> is this?
      </Title>

      <div className={styles.option_icons}>
        {radio.map(item => {
          const { name, title } = item

          return (
            <RadioIcon
              key={name}
              name={filterName}
              value={name}
              checked={value === name}
              title={title}
              icon={name}
              onChange={onFieldChange}
            />
          )
        })}
      </div>
    </div>
  )
}
