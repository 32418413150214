import { changeSearchField, getSearchForm, Insurance } from '@/store/slices/registrationSlice'
import { useSelector, useDispatch } from 'react-redux'
import { Title, Text, Radio } from '@/components'
import styles from '../SearchPage.module.scss'

const filterName = 'insurance'
const radio: { name: Insurance; title: string }[] = [
  { name: 'yes', title: 'Yes' },
  { name: 'no', title: 'No' },
]

export default function StepFive() {
  const dispatch = useDispatch()

  const { data } = useSelector(getSearchForm)
  const value = data[filterName]

  const onFieldChange = (value: Insurance) => {
    dispatch(changeSearchField({ fieldName: filterName, fieldValue: value }))
  }

  return (
    <div className={styles.content}>
      <Title size="medium" align="center">
        Is this request covered by an <Text mark>insurance claim?</Text>
      </Title>

      <div className={styles.options}>
        {radio.map(item => {
          const { name, title } = item

          return (
            <Radio
              key={name}
              name={filterName}
              value={name}
              checked={value === name}
              title={title}
              onChange={onFieldChange}
            />
          )
        })}
      </div>
    </div>
  )
}
