import { forwardRef, useRef, memo, useCallback, ChangeEventHandler } from 'react'
import { InputProps } from './interfaces'
import styles from './Input.module.scss'
import mergeRefs from 'react-merge-refs'
import cn from 'classnames'

const Input = memo(
  forwardRef((props: InputProps, inputRef) => {
    const {
      id,
      value,
      onChange,
      placeholder = '',
      danger = false,
      disabled = false,
      type = 'text',
      inputMode = 'text',
    } = props
    const ref = useRef<HTMLInputElement>(null)

    const _onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      e => {
        onChange(e.target.value)
      },
      [onChange],
    )

    return (
      <input
        id={id}
        ref={mergeRefs([ref, inputRef])}
        value={value}
        onChange={_onChange}
        className={cn(styles.input, { [styles.danger]: danger })}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        inputMode={inputMode}
      />
    )
  }),
)

export default Input
