import { changeSearchField, getSearchForm, TimeToStart } from '@/store/slices/registrationSlice'
import { useSelector, useDispatch } from 'react-redux'
import { Title, Text, Radio } from '@/components'
import styles from '../SearchPage.module.scss'

const filterName = 'time_to_start'
const radio: { name: TimeToStart; title: string }[] = [
  { name: 'within 48 hours', title: 'Within 48 hours' },
  { name: 'within a week', title: 'Within a week' },
  { name: 'within a month', title: 'Within a month' },
  { name: 'within a year', title: 'Within a year' },
  { name: 'flexible', title: 'Flexible' },
  { name: 'not sure', title: 'Not sure' },
]

export default function StepFour() {
  const dispatch = useDispatch()

  const { data } = useSelector(getSearchForm)
  const value = data[filterName]

  const onFieldChange = (value: TimeToStart) => {
    dispatch(changeSearchField({ fieldName: filterName, fieldValue: value }))
  }

  return (
    <div className={styles.content}>
      <Title size="medium" align="center">
        <Text mark>When</Text> do you need to start the project?
      </Title>

      <div className={styles.options}>
        {radio.map(item => {
          const { name, title } = item

          return (
            <Radio
              key={name}
              name={filterName}
              value={name}
              checked={value === name}
              title={title}
              onChange={onFieldChange}
            />
          )
        })}
      </div>
    </div>
  )
}
