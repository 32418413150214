import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '42',
  height = '58',
  viewBox = '0 0 42 58',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M37.834 15.785V.808c0-.485-.324-.808-.808-.808H4.58c-.485 0-.808.323-.808.808v54.826H.808c-.485 0-.808.323-.808.807 0 .485.323.808.808.808h39.981c.485 0 .808-.323.808-.808 0-.484-.323-.807-.808-.807h-2.965V27.969l-1.625.01v27.655h-1.625V25.935l-1.606-1.406v31.105H8.638V4.866h24.33v14.683l1.625-1.14V4.057c0-.485-.323-.808-.808-.808H7.831c-.485 0-.808.323-.808.808v51.585H5.398V1.625h30.82v14.132l1.616.028z" />
    <path d="M13.638 33.937a2.281 2.281 0 110-4.563 2.281 2.281 0 010 4.563zm0-3.212a.933.933 0 00-.931.931c0 .513.418.931.93.931a.933.933 0 00.932-.93.933.933 0 00-.931-.932zM30.81 15.671a7.286 7.286 0 01-5.626-5.626.76.76 0 00-.731-.599.751.751 0 00-.732.6 7.286 7.286 0 01-5.626 5.625.76.76 0 00-.599.732c0 .352.247.656.599.732a7.286 7.286 0 015.626 5.626.76.76 0 00.732.599.751.751 0 00.731-.599 7.286 7.286 0 015.627-5.626.76.76 0 00.598-.732.75.75 0 00-.598-.732zM30.81 28.235a3.435 3.435 0 01-2.651-2.651.76.76 0 00-.732-.6.75.75 0 00-.732.6 3.434 3.434 0 01-2.651 2.651.76.76 0 00-.599.732c0 .351.247.655.599.732a3.434 3.434 0 012.651 2.651.76.76 0 00.732.599.75.75 0 00.732-.599 3.434 3.434 0 012.652-2.651.76.76 0 00.598-.732.743.743 0 00-.598-.732zM41.207 21.107a4.649 4.649 0 01-3.583-3.582.76.76 0 00-.731-.6.751.751 0 00-.732.6 4.649 4.649 0 01-3.583 3.582.76.76 0 00-.599.732.75.75 0 00.6.732 4.649 4.649 0 013.582 3.583.76.76 0 00.732.599.751.751 0 00.731-.6 4.649 4.649 0 013.583-3.582.76.76 0 00.599-.732.75.75 0 00-.599-.732z" />
  </svg>
)

export default SVG
