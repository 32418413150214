import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '13',
  height = '12',
  viewBox = '0 0 13 12',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M.464.343a1 1 0 011.414 0l9.9 9.9a1 1 0 01-1.414 1.413l-9.9-9.9a1 1 0 010-1.413z" />
    <path d="M.464 11.656a1 1 0 010-1.414l9.9-9.9a1 1 0 111.414 1.415l-9.9 9.9a1 1 0 01-1.414 0z" />
  </svg>
)

export default SVG
