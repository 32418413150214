import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '14',
  height = '14',
  viewBox = '0 0 14 14',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 7a1 1 0 011-1h12a1 1 0 110 2H1a1 1 0 01-1-1z" />
    <path d="M7 0a1 1 0 011 1v12a1 1 0 11-2 0V1a1 1 0 011-1z" />
  </svg>
)

export default SVG
