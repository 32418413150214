import {
  LayoutProps,
  LayoutDefaultChildren,
  LayoutHalvedChildren,
  isDefaultLayout,
} from './interfaces'
import { MobileNav, MobileNavToggle } from '@/components'
import LayoutDefault from './LayoutDefault'
import LayoutHalved from './LayoutHalved'
import styles from './Layout.module.scss'
import cn from 'classnames'

export default function Layout(props: LayoutProps) {
  const { type = 'default', theme = 'default', children } = props

  return (
    <div
      className={cn(styles.wrap, { [styles.gradient]: type === 'default' && theme === 'default' })}
    >
      <MobileNav />

      <div className={styles.menu_toggler}>
        <MobileNavToggle dark={theme !== 'dark'} />
      </div>

      {isDefaultLayout(props) ? (
        <LayoutDefault theme={theme} noMobileFooter={props.noMobileFooter}>
          {children as LayoutDefaultChildren}
        </LayoutDefault>
      ) : (
        <LayoutHalved>{children as LayoutHalvedChildren}</LayoutHalved>
      )}
    </div>
  )
}
