import styles from './Notification.module.scss'
import cn from 'classnames'

type NotificationProps = {
  content: React.ReactNode
  onClose: () => void
}

export default function Notification({ content, onClose }: NotificationProps) {
  return (
    <div className={cn(styles.notification, styles.error)}>
      <div className={styles.body}>
        <div className={styles.content}>{content}</div>
        <button className={styles.close} type="button" onClick={onClose} />
      </div>
    </div>
  )
}
