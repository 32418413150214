import styles from './Container.module.scss'
import cn from 'classnames'

type ContainerProps = {
  className?: string
  size?: 'wide' | 'halved'
  children: React.ReactNode
}

export default function Container({ size, className, children }: ContainerProps) {
  return (
    <div
      className={cn({
        [styles.container]: true,
        [styles.wide]: size === 'wide',
        [styles.halved]: size === 'halved',
        ...(className && { [className]: true }),
      })}
    >
      {children}
    </div>
  )
}
