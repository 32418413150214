import styles from './Form.module.scss'
import mergeRefs from 'react-merge-refs'
import { forwardRef, useRef } from 'react'

interface FormProps {
  children: React.ReactNode
  onSubmit: (e: React.FormEvent) => void
}

export default function Form({ onSubmit, children }: FormProps) {
  return (
    <form action="/" autoComplete="off" className={styles.form} onSubmit={onSubmit}>
      {children}
    </form>
  )
}

Form.Row = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.row}>{children}</div>
}

Form.Footer = forwardRef(({ children }: { children: React.ReactNode }, outerRef) => {
  const ref = useRef<HTMLDivElement>(null)
  return (
    <div ref={mergeRefs([ref, outerRef])} className={styles.footer}>
      {children}
    </div>
  )
})

Form.Divider = () => <div className={styles.divider} />
