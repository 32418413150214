import { IconComponentType, IconWrapperProps } from './interfaces'
import ArrangementSecurity from './ArrangementSecurityIcon'
import Business from './BusinessIcon'
import CloseBig from './CloseBigIcon'
import Close from './CloseIcon'
import DevLogo from './DevLogo'
import Distance from './DistanceIcon'
import Drill from './DrillIcon'
import File from './FileIcon'
import FinancialSecurity from './FinancialSecurityIcon'
import Home from './HomeIcon'
import Minus from './MinusIcon'
import PdfFile from './PdfFileIcon'
import Person from './PersonIcon'
import Plus from './PlusIcon'
import Question from './QuestionIcon'
import Quote from './QuoteIcon'
import Service from './ServiceIcon'
import ShiningDoor from './ShiningDoorIcon'
import Star from './StarIcon'
import Success from './SuccessIcon'
import Upload from './UploadIcon'

const Icon = ({ name, ...props }: IconWrapperProps) => {
  let Component: IconComponentType
  Component = Person

  switch (name) {
    case 'arrangement-security': {
      Component = ArrangementSecurity
      break
    }
    case 'business': {
      Component = Business
      break
    }
    case 'close-big': {
      Component = CloseBig
      break
    }
    case 'close': {
      Component = Close
      break
    }
    case 'dev-logo': {
      Component = DevLogo
      break
    }
    case 'distance': {
      Component = Distance
      break
    }
    case 'drill': {
      Component = Drill
      break
    }
    case 'file': {
      Component = File
      break
    }
    case 'financial-security': {
      Component = FinancialSecurity
      break
    }
    case 'home': {
      Component = Home
      break
    }
    case 'minus': {
      Component = Minus
      break
    }
    case 'person': {
      Component = Person
      break
    }
    case 'pdf-file': {
      Component = PdfFile
      break
    }
    case 'plus': {
      Component = Plus
      break
    }
    case 'question': {
      Component = Question
      break
    }
    case 'quote': {
      Component = Quote
      break
    }
    case 'service': {
      Component = Service
      break
    }
    case 'shining-door': {
      Component = ShiningDoor
      break
    }
    case 'star': {
      Component = Star
      break
    }
    case 'success': {
      Component = Success
      break
    }
    case 'upload': {
      Component = Upload
      break
    }
  }

  return <Component {...props} />
}

export default Icon
