import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '15',
  height = '2',
  viewBox = '0 0 15 2',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="15" height="2" rx="1" />
  </svg>
)

export default SVG
