import { LayoutHalvedProps } from './interfaces'
import { Header, Footer } from '@/components'
import styles from './Layout.module.scss'

export default function LayoutHalved({ children }: LayoutHalvedProps) {
  const { mainContent, sideContent } = children

  return (
    <>
      <div className={styles.halved}>
        <div className={styles.halved_inner}>
          <Header type="half" />
          <main className={styles.content}>{mainContent}</main>
          <Footer type="half" />
        </div>

        <div className={styles.halved_side}>{sideContent}</div>
      </div>
    </>
  )
}
