import { Picture as PictureProps } from './Picture'
import { useState, useEffect } from 'react'
import { Picture } from '@/components'

const placeHolder =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='

export default function LazyPicture({ data, ...props }: { data: PictureProps }) {
  const [imageSrc, setImageSrc] = useState({
    desktop: {
      x1: placeHolder,
      x2: placeHolder,
      webp_x1: placeHolder,
      webp_x2: placeHolder,
    },
    tablet: {
      x1: placeHolder,
      x2: placeHolder,
      webp_x1: placeHolder,
      webp_x2: placeHolder,
    },
    mobile: {
      x1: placeHolder,
      x2: placeHolder,
      webp_x1: placeHolder,
      webp_x2: placeHolder,
    },
  })
  const [imageRef, setImageRef] = useState<HTMLImageElement>()

  const onLoad = (event: any) => {
    const target: HTMLImageElement = event.target
    if (target.src !== placeHolder) target?.classList.add('loaded')
  }

  const onError = (event: any) => {
    const target: HTMLImageElement = event.target
    if (target.src !== placeHolder) target?.classList.add('has-error')
  }

  useEffect(() => {
    if (imageSrc?.desktop?.x1 !== placeHolder) {
      setImageSrc(data)
    }
  }, [data])

  useEffect(() => {
    let observer: IntersectionObserver
    let didCancel = false

    if (imageRef && imageSrc?.desktop?.x1 === placeHolder) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              // when image is visible in the viewport + rootMargin
              if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                setImageSrc(data)
                observer.unobserve(imageRef)
              }
            })
          },
          {
            threshold: 0.01,
            rootMargin: '20%',
          },
        )
        observer.observe(imageRef)
      } else {
        // Old browsers fallback
        setImageSrc(data)
      }
    }
    return () => {
      didCancel = true
      // on component unmount, we remove the listner
      if (observer && observer.unobserve && imageRef) {
        observer.unobserve(imageRef)
      }
    }
  }, [data, imageSrc, imageRef])

  return (
    <Picture ref={setImageRef} {...imageSrc} {...props} onLoad={onLoad} onLoadError={onError} />
  )
}
