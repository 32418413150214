import { useNav, ContextState } from '@/contexts/NavContext'
import { useCallback, memo } from 'react'
import { Burger } from '@/components'

interface NavToggleProps extends ContextState {
  dark: boolean
}

export default function MobileNavToggle({ dark }: { dark: boolean }) {
  const { visible, toggle } = useNav()
  return <MemoizedMobileNavToggle visible={visible} toggle={toggle} dark={dark} />
}

const MemoizedMobileNavToggle = memo(({ visible, toggle, dark }: NavToggleProps) => {
  const onClick = useCallback(e => {
    e.stopPropagation()
    toggle()
  }, [])

  return <Burger open={visible} onClick={onClick} dark={dark} />
})

MemoizedMobileNavToggle.whyDidYouRender = true
