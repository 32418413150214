import { SearchFields } from '@/store/slices/registrationSlice'
import { Collapse, Drawer, Title, Text } from '@/components'
import styles from './Description.module.scss'
import { useState, useCallback } from 'react'

export default function Description({ data }: { data: SearchFields }) {
  const {
    zip_code,
    types_of_doors,
    how_many,
    location,
    time_to_start,
    insurance,
    door_schedule,
    about_project,
  } = data
  const [drawerVisible, setDrawerVisible] = useState(false)

  const doorsTypeAndQuantity = `${how_many} ${types_of_doors
    .map(type => type.charAt(0).toUpperCase() + type.slice(1))
    .join(' & ')} ${how_many === '1' ? 'Door' : 'Doors'}`
  const doorsLocation = location === 'home' ? 'Home/Residence' : 'Business'
  const startDate =
    time_to_start === 'flexible'
      ? 'with flexible installation start date'
      : time_to_start === 'not sure'
      ? 'with installation'
      : `with installation ${time_to_start}`
  const insuranceState =
    insurance === 'yes' ? 'Covered by an insurance' : 'Not covered by an insurance'
  const doorScheduleState = door_schedule === 'yes' ? 'With door schedule' : 'Without door schedule'
  const finalDescription = `${doorsTypeAndQuantity} at ${doorsLocation} ${startDate}. ${insuranceState}. ${doorScheduleState}.`

  const onBtnClick = useCallback(() => {
    setDrawerVisible(true)
  }, [])

  return (
    <>
      <div className={styles.description}>
        <strong>ZIP code: {zip_code}</strong>
        <br />
        <p>
          {finalDescription}{' '}
          {about_project && (
            <button className={styles.drawer_btn} onClick={onBtnClick}>
              View project description.
            </button>
          )}
        </p>
      </div>

      <div className={styles.collapse}>
        <Collapse title={`ZIP code: ${zip_code}`}>
          {finalDescription}{' '}
          {about_project && (
            <button className={styles.drawer_btn} onClick={onBtnClick}>
              View project description.
            </button>
          )}
        </Collapse>
      </div>

      <Drawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        title={
          <Title tag="span" size="medium">
            Project <Text mark>description</Text>
          </Title>
        }
      >
        {about_project}
      </Drawer>
    </>
  )
}
