import styles from './TextPage.module.scss'
import { Container, Title, Html } from '@/components'
import { PageData } from '@/store/slices/pagesSlice'

export default function TextPage({ header, text }: PageData) {
  return (
    <div className={styles.page}>
      <Container>
        <div className={styles.limiter}>
          <Title>{header}</Title>

          <Html html={text} />
        </div>
      </Container>
    </div>
  )
}
