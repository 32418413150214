import styles from './Text.module.scss'

type TextProps = {
  mark?: boolean
  children: React.ReactNode
}

export default function Text({ mark, children }: TextProps) {
  const Wrapper = mark ? 'mark' : 'span'

  return (
    <span className={styles.text}>
      <Wrapper>{children}</Wrapper>
    </span>
  )
}
