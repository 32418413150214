import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '25',
  height = '25',
  viewBox = '0 0 25 25',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1.414" width="32" height="2" rx="1" transform="rotate(45 1.414 0)" />
    <rect y="22.627" width="32" height="2" rx="1" transform="rotate(-45 0 22.627)" />
  </svg>
)

export default SVG
