import { ActiveLink, Container, Icon } from '@/components'
import getLinkHref from '@/helpers/getLinkHref'
import { useNav } from '@/contexts/NavContext'
import styles from './MobileNav.module.scss'
import cn from 'classnames'

export default function MobileNav() {
  const { visible, toggle } = useNav()

  return (
    <div
      className={cn({
        [styles.wrapper]: true,
        [styles.visible]: visible,
      })}
    >
      <div className={styles.menu}>
        <ActiveLink href="/" activeClass={styles.active} onClick={toggle}>
          <a className={styles.logo}>Doors Install</a>
        </ActiveLink>

        <Container className={styles.inner}>
          <ActiveLink
            href={getLinkHref({ type: 'projects' })}
            activeClass={styles.active}
            onClick={toggle}
          >
            <a className={styles.nav_link}>Our projects</a>
          </ActiveLink>

          <ActiveLink
            href={getLinkHref({ type: 'registration' })}
            activeClass={styles.active}
            onClick={toggle}
          >
            <a className={styles.nav_link}>
              <span className={styles.nav_icon}>
                <Icon name="drill" />
              </span>
              Installer sign up
            </a>
          </ActiveLink>
        </Container>
      </div>
    </div>
  )
}
