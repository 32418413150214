import { forwardRef, useRef, memo, useCallback, ChangeEventHandler } from 'react'
import { InputProps } from './interfaces'
import styles from './Input.module.scss'
import mergeRefs from 'react-merge-refs'
import cn from 'classnames'

const TextArea = memo(
  forwardRef((props: InputProps, inputRef) => {
    const { id, value, onChange, placeholder = '', danger = false, disabled = false } = props
    const ref = useRef<HTMLTextAreaElement>(null)

    const _onChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
      e => {
        onChange(e.target.value)
      },
      [onChange],
    )

    return (
      <textarea
        id={id}
        ref={mergeRefs([ref, inputRef])}
        value={value}
        onChange={_onChange}
        className={cn(styles.input, styles.textarea, { [styles.danger]: danger })}
        placeholder={placeholder}
        disabled={disabled}
      />
    )
  }),
)

export default TextArea
