import styles from './Section.module.scss'
import cn from 'classnames'

type SectionProps = {
  className?: string
  children: React.ReactNode
}

export default function Section({ className, children }: SectionProps) {
  return (
    <section
      className={cn(styles.section, {
        ...(className && { [className]: true }),
      })}
    >
      {children}
    </section>
  )
}
