import {
  Section,
  Container,
  Title,
  Paragraph,
  Text,
  ClosestByZip,
  FullWidthSlider,
  Project,
  GalleryWrapper,
  LazyPicture,
} from '@/components'
import styles from './HomePage.module.scss'
import { getProjects } from '@/store/slices/projectsSlice'
import getLinkHref from '@/helpers/getLinkHref'
import { useSelector } from 'react-redux'
import Link from 'next/link'
import Advantage from './Advantage'
import Steps from './Steps'

export default function HomePage() {
  const { data } = useSelector(getProjects)
  const projects = data.slice(0, 7)

  return (
    <>
      <Section className={styles.description}>
        <Container className={styles.container}>
          <div>
            <Title level={2}>
              How our service <br />
              <Text mark>works</Text>
            </Title>
            <Paragraph>
              This service is aimed to provide you an easy search of the trained and certified
              Installer for your Modern Prefinished doors.
            </Paragraph>
            <Paragraph>
              Why is this important? Because an approved installer will make sure your door
              installation complies with building regulation and product requirements, and your
              invested time and money will not go to waste.
            </Paragraph>
            <Paragraph medium>
              There's an easy way to look for an approved installation specialist.
            </Paragraph>
          </div>
          <div className={styles.img}>
            <LazyPicture
              data={{
                desktop: {
                  x1: '/images/master/img-d.jpg',
                  x2: '/images/master/img-d.jpg',
                  webp_x1: '/images/master/img-d.webp',
                  webp_x2: '/images/master/img-d.webp',
                },
                tablet: {
                  x1: '/images/master/img-t.jpg',
                  x2: '/images/master/img-t.jpg',
                  webp_x1: '/images/master/img-t.webp',
                  webp_x2: '/images/master/img-t.webp',
                },
                mobile: {
                  x1: '/images/master/img-m.jpg',
                  x2: '/images/master/img-m.jpg',
                  webp_x1: '/images/master/img-m.webp',
                  webp_x2: '/images/master/img-m.webp',
                },
              }}
            />
          </div>
          <div className={styles.steps}>
            <Steps />
          </div>
        </Container>
      </Section>

      <section className={styles.search}>
        <Container className={styles.dialog}>
          <div className={styles.img}>
            <LazyPicture
              data={{
                desktop: {
                  x1: '/images/search-home/img-d.png',
                  x2: '/images/search-home/img-d.png',
                  webp_x1: '/images/search-home/img-d.webp',
                  webp_x2: '/images/search-home/img-d.webp',
                },
                tablet: {
                  x1: '/images/search-home/img-t.png',
                  x2: '/images/search-home/img-t.png',
                  webp_x1: '/images/search-home/img-t.webp',
                  webp_x2: '/images/search-home/img-t.webp',
                },
                mobile: {
                  x1: '/images/search-home/img-m.png',
                  x2: '/images/search-home/img-m.png',
                  webp_x1: '/images/search-home/img-m.webp',
                  webp_x2: '/images/search-home/img-m.webp',
                },
              }}
            />
          </div>
          <div>
            <Title level={3} size="medium">
              Find the installer for <Text mark>free</Text>
            </Title>
            <Paragraph>
              To keep your doors operating well, a proper installation is necessary. A job done by a
              professional can ensure that.
            </Paragraph>

            <ClosestByZip />
          </div>
        </Container>
      </section>

      <Section className={styles.projects}>
        <Container>
          <div className={styles.header}>
            <Title level={2}>Our projects</Title>
            <Link href={getLinkHref({ type: 'projects' })}>
              <a className={styles.link}>View All</a>
            </Link>
          </div>
        </Container>

        <FullWidthSlider>
          {projects.map((project, ind) => {
            return (
              <GalleryWrapper
                key={ind}
                images={project.media}
                name={project.name}
                description={project.description}
              >
                <Project {...project} />
              </GalleryWrapper>
            )
          })}
        </FullWidthSlider>
      </Section>

      <Section className={styles.advantages}>
        <Container>
          <Title level={2}>
            Why do you need <br />a <Text mark>professional</Text> installer?
          </Title>
          <div className={styles.advantage_list}>
            <Advantage
              title="Aesthetic"
              description="Be sure that your prefinished door will show all the advantages of exquisite design."
              icon="shining-door"
            />
            <Advantage
              title="Lifetime Service"
              description="Be sure that all the components and hardware are installed properly and will continue working in long term."
              icon="service"
            />
          </div>
          <div className={styles.advantage_list}>
            <Advantage
              title="Financial Security"
              description="Be sure that you will get the most out of your money with the purchase."
              icon="financial-security"
              className={styles.icon_shift}
            />
            <Advantage
              title="Arrangement Security"
              description="Be sure that installer has experience in installing prefinished doors and can guarantee the result."
              icon="arrangement-security"
              className={styles.icon_shift}
            />
          </div>
        </Container>
      </Section>
    </>
  )
}
