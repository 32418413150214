import { findMastersByZip, getSearchForm } from '@/store/slices/registrationSlice'
import { useNotification } from '@/contexts/NotificationContext'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Container } from '@/components'
import { SearchPageProps } from './interfaces'
import styles from './SearchPage.module.scss'
import StepOne from './steps/StepOne'
import StepTwo from './steps/StepTwo'
import StepThree from './steps/StepThree'
import StepFour from './steps/StepFour'
import StepFive from './steps/StepFive'
import StepSix from './steps/StepSix'
import StepSeven from './steps/StepSeven'
import Router from 'next/router'
import Link from 'next/link'

export default function SearchPage({ step, steps, code }: SearchPageProps) {
  const notification = useNotification()

  const stepsString = `Step ${step} of ${steps}`
  const prevHref = step === 1 ? '/' : `/search/step-${step - 1}?code=${code}`
  const nextHref = `/search/step-${step + 1}?code=${code}`
  const thumbStyle = { transform: `translate3d(${(step * 100) / steps}%, 0, 0)` }

  const dispatch = useDispatch()
  const { loading } = useSelector(getSearchForm)

  const onMasterSearchClick = () => {
    dispatch(
      findMastersByZip({
        zip: code,
        onSuccess: () => {
          Router.push(`/search/results?code=${code}`)
        },
        onError: () => {
          notification.add({
            content: <p>Sorry, could not find the masters.</p>,
          })
        },
      }),
    )
  }

  return (
    <Container className={styles.container}>
      <div className={styles.bar}>
        <div className={styles.thumb} style={thumbStyle}></div>
      </div>
      <div className={styles.steps}>{stepsString}</div>

      <div className={styles.limiter}>
        {step === 1 ? (
          <StepOne />
        ) : step === 2 ? (
          <StepTwo />
        ) : step === 3 ? (
          <StepThree />
        ) : step === 4 ? (
          <StepFour />
        ) : step === 5 ? (
          <StepFive />
        ) : step === 6 ? (
          <StepSix />
        ) : step === 7 ? (
          <StepSeven />
        ) : null}

        <div className={styles.footer}>
          <Link href={prevHref} passHref>
            <Button size="large" variant="primary" color="navigation" component="a">
              Prev
            </Button>
          </Link>

          {step === steps ? (
            <Button
              size="large"
              variant="primary"
              color="decor"
              disabled={loading}
              onClick={onMasterSearchClick}
            >
              Next
            </Button>
          ) : (
            <Link href={nextHref} passHref>
              <Button size="large" variant="primary" color="decor" component="a">
                Next
              </Button>
            </Link>
          )}
        </div>
      </div>
    </Container>
  )
}
