import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '24',
  height = '22',
  viewBox = '0 0 24 22',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.771 0H7.885C3.537 0 0 3.54 0 7.893v2.083a7.878 7.878 0 005.368 7.479c-.4.691-.946 1.769-1.73 3.452a.768.768 0 001.017 1.023l8.878-4.061h2.238c4.348 0 7.886-3.54 7.886-7.893V7.892C23.657 3.54 20.119 0 15.77 0zm6.348 9.976c0 3.504-2.848 6.356-6.348 6.356h-2.406c-.11 0-.22.024-.32.07l-7.087 3.242c.472-.937.901-1.73 1.103-1.993a.769.769 0 00-.265-1.415 6.345 6.345 0 01-5.26-6.26V7.893c0-3.504 2.849-6.356 6.35-6.356h7.885c3.5 0 6.349 2.852 6.349 6.356v2.083z"
      fill={fill}
    />
    <path
      d="M5.87 10.81a1.7 1.7 0 100-3.4 1.7 1.7 0 000 3.4zM17.786 10.81a1.7 1.7 0 100-3.4 1.7 1.7 0 000 3.4zM11.828 10.81a1.7 1.7 0 100-3.4 1.7 1.7 0 000 3.4z"
      fill={fill}
    />
  </svg>
)

export default SVG
