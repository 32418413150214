import {
  getSearchForm,
  changeSearchField,
  resetContactMasterState,
  Master as MasterProps,
} from '@/store/slices/registrationSlice'
import { Button, Container, Title, Text, Link as CustomLink, Drawer, Paragraph } from '@/components'
import { useState, useCallback, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styles from './SearchResults.module.scss'
import findPos from '@/helpers/findPos'
import Description from './Description'
import SearchForm from '../SearchForm'
import Master from './Master'
import Link from 'next/link'
import cn from 'classnames'

export default function SearchResults({ code }: { code: string }) {
  const dispatch = useDispatch()
  const { data, masters, sent } = useSelector(getSearchForm)
  const prevHref = `/search/step-7?code=${code}`

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [stickyFooter, setStickyFooter] = useState(false)
  const footerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (footerRef.current) {
      if (findPos(footerRef.current) >= window.innerHeight) setStickyFooter(true)
    }
  }, [])

  const onSendToSingleClick = useCallback((id: number) => {
    dispatch(changeSearchField({ fieldName: 'masters', fieldValue: [id] }))
    setDrawerVisible(true)
  }, [])

  const onSendToAllClick = useCallback(() => {
    const ids = masters.map(master => master.id)
    dispatch(changeSearchField({ fieldName: 'masters', fieldValue: ids }))
    setDrawerVisible(true)
  }, [masters])

  const onDrawerClose = useCallback(() => {
    setDrawerVisible(false)
    dispatch(resetContactMasterState())
  }, [])

  const onDrawerCloseReset = useCallback(() => {
    setDrawerVisible(false)
    dispatch(resetContactMasterState(true))
  }, [])

  const sendToAllSelected = data.masters.length > 1
  const selectedMaster = masters.find(master => master.id === data.masters[0]) ?? null
  const drawerTitle = sent ? null : sendToAllSelected ? (
    <Title tag="span" size="medium">
      Get a quote
      <br /> from <Text mark>all installers</Text>
    </Title>
  ) : (
    <Title tag="span" size="medium">
      Get a quote
      <br /> from a chosen <Text mark>pro</Text>
    </Title>
  )

  return (
    <Container className={styles.container}>
      <Description data={{ ...data, zip_code: code }} />

      <Title>
        Contractors <Text mark>at your area</Text>
      </Title>

      <div className={styles.masters}>
        {masters.map(master => (
          <Master key={master.id} {...master} onClick={onSendToSingleClick} />
        ))}
      </div>

      <div className={cn(styles.footer, { [styles.sticky]: stickyFooter })} ref={footerRef}>
        <Link href={prevHref} passHref>
          <Button size="large" variant="primary" color="navigation" component="a">
            Prev
          </Button>
        </Link>

        <Button size="large" variant="primary" color="decor" onClick={onSendToAllClick}>
          Send Request to All
        </Button>
      </div>

      <Drawer visible={drawerVisible} onClose={onDrawerClose} title={drawerTitle}>
        {sent ? (
          <div className={styles.success}>
            <Title tag="span" size="medium" align="center">
              Thanks
              <br /> for your <Text mark>request!</Text>
            </Title>
            <Paragraph align="center">
              Your request was successfully sent to the chosen Installer. Installer will contact you
              soon. Thank you for using our service!
            </Paragraph>
            <CustomLink button onClick={onDrawerClose} className={styles.link}>
              Back to List of Installers
            </CustomLink>
            <CustomLink href="/" onClick={onDrawerCloseReset} className={styles.link}>
              New Search
            </CustomLink>
          </div>
        ) : (
          <ContactForm code={code} sendToAll={sendToAllSelected} master={selectedMaster} />
        )}
      </Drawer>
    </Container>
  )
}

type ContactFormProps = {
  master: MasterProps | null
  sendToAll: boolean
  code: string
}

function ContactForm({ master, sendToAll, code }: ContactFormProps) {
  return (
    <>
      {master && !sendToAll && (
        <>
          <div className={styles.master_name}>
            {master.first_name} {master.last_name}
          </div>
          <div className={styles.master_phone}>
            <a href={`tel: ${master.phone}`}>{master.phone}</a>
          </div>
        </>
      )}

      <SearchForm code={code} />
    </>
  )
}
