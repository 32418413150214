import { ActiveLink, Container, Icon } from '@/components'
import getLinkHref from '@/helpers/getLinkHref'
import styles from './Footer.module.scss'
import { FooterProps } from './interfaces'
import cn from 'classnames'

export default function Footer({
  type = 'default',
  noMobileFooter = false,
  children,
}: FooterProps) {
  const year = new Date().getFullYear()

  return (
    <footer
      className={cn({
        [styles.footer]: true,
        [styles.home]: type === 'home',
        [styles.default]: type === 'default',
        [styles.half]: type === 'half',
        [styles.no_mobile]: noMobileFooter,
      })}
    >
      <Container {...(type === 'half' && { size: 'halved' })}>
        {children}

        <div className={styles.bar}>
          <div className={styles.copyright}>© {year} Doors Install. All Rights Reserved</div>

          <ul className={styles.nav}>
            <li>
              <ActiveLink href={getLinkHref({ type: 'projects' })} activeClass={styles.active}>
                <a className={styles.link}>Our projects</a>
              </ActiveLink>
            </li>
            <li>
              <ActiveLink href="/terms-and-conditions" activeClass={styles.active}>
                <a className={styles.link}>Terms and Conditions</a>
              </ActiveLink>
            </li>
          </ul>

          <div className={styles.dev}>
            <a
              className={styles.devLogo}
              href="https://dev.family?utm_source=doors-install&utm_medium=link&utm_campaign=doors-install"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Developed by</span>
              <Icon name="dev-logo" />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  )
}
