import { useContext, useState, createContext, useCallback } from 'react'
import noScroll from 'no-scroll'

export interface ContextState {
  visible: boolean
  toggle: () => void
}

const NavContext = createContext({} as ContextState)

type NavProviderProps = {
  children: React.ReactNode
}

export function NavProvider({ children }: NavProviderProps) {
  const [visible, setVisible] = useState(false)

  const toggle = useCallback(() => {
    setVisible(prev => !prev)
    noScroll.toggle()
  }, [])

  return (
    <NavContext.Provider
      value={{
        visible,
        toggle,
      }}
    >
      {children}
    </NavContext.Provider>
  )
}

export function useNav() {
  return useContext(NavContext)
}
