import Swiper, { ReactIdSwiperChildren, ReactIdSwiperProps } from 'react-id-swiper'
import styles from './FullWidthSlider.module.scss'
import { Container } from '@/components'

type FullWidthSliderProps = {
  children: ReactIdSwiperChildren
}

const params: ReactIdSwiperProps = {
  resistanceRatio: 0,
  slidesPerView: 'auto',
  watchOverflow: true,
  watchSlidesVisibility: true,
}

export default function FullWidthSlider({ children }: FullWidthSliderProps) {
  return (
    <div className={styles.slider}>
      <Container>
        <div className={styles.swiper}>
          <Swiper {...params}>{children}</Swiper>
        </div>
      </Container>
    </div>
  )
}
