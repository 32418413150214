import { Container, Title, Text, Paragraph, Project, GalleryWrapper } from '@/components'
import { getProjects } from '@/store/slices/projectsSlice'
import styles from './ProjectsPage.module.scss'
import { useSelector } from 'react-redux'

export default function ProjectsPage() {
  const { data: projects } = useSelector(getProjects)

  return (
    <section className={styles.page}>
      <Container>
        <Title>
          Our <Text mark>projects</Text>
        </Title>
        <Paragraph>
          This service is aimed to provide you an easy search of the trained and certified Installer
          for your Modern Prefinished doors. Why is this important? Because an approved installer
          will make sure your doors installation complies with building regulation and product
          requirements.
        </Paragraph>

        <div className={styles.projects}>
          {projects.map((project, ind) => {
            return (
              <GalleryWrapper
                key={ind}
                images={project.media}
                name={project.name}
                description={project.description}
              >
                <Project {...project} />
              </GalleryWrapper>
            )
          })}
        </div>
      </Container>
    </section>
  )
}
