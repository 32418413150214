import { memo, forwardRef, useRef, useCallback, ChangeEventHandler } from 'react'
import { RadioProps } from './interfaces'
import styles from './Choice.module.scss'
import mergeRefs from 'react-merge-refs'
import cn from 'classnames'

const Radio = memo(
  forwardRef((props: RadioProps, inputRef) => {
    const { value, checked, onChange, disabled = false, title, name, ...rest } = props
    const ref = useRef<HTMLInputElement>(null)

    const _onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      e => {
        onChange(e.target.value)
      },
      [onChange],
    )

    return (
      <label className={cn(styles.choice, styles.radio)} {...rest}>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={_onChange}
          disabled={disabled}
          className={styles.input}
          ref={mergeRefs([ref, inputRef])}
        />
        <span className={styles.title}>{title}</span>
      </label>
    )
  }),
)

export default Radio
