import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '10',
  height = '8',
  viewBox = '0 0 10 8',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2.231" y="6.309" width="8.532" height="2" rx="1" transform="rotate(-45 2.231 6.31)" />
    <rect x="1.735" y="3.022" width="4.648" height="2" rx="1" transform="rotate(45 1.735 3.022)" />
  </svg>
)

export default SVG
