import { useCallback, useState } from 'react'
import { Icon, Drawer, Title, Text } from '@/components'
import styles from './Master.module.scss'
import { FileType } from '@/store/slices/registrationSlice'

interface CertificatesProps {
  files: FileType[]
}

export default function Certificates({ files }: CertificatesProps) {
  const [drawerVisible, setDrawerVisible] = useState(false)

  const onDrawerOpen = useCallback(() => {
    setDrawerVisible(true)
  }, [])

  const onDrawerClose = useCallback(() => {
    setDrawerVisible(false)
  }, [])

  const drawerTitle = (
    <Title tag="span" size="medium">
      Project <Text mark>certificates</Text>
    </Title>
  )
  return (
    <>
      <button type="button" className={styles.certificatesBtn} onClick={onDrawerOpen}>
        <Icon name="pdf-file" />
        {files.length > 1 ? 'Certificates' : 'Certificate'}
      </button>

      <Drawer visible={drawerVisible} onClose={onDrawerClose} title={drawerTitle}>
        <div className={styles.certificateList}>
          {files.map((file, idx) => (
            <a
              key={idx}
              className={styles.certificate}
              href={file.file}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="pdf-file" />
              Certificate {idx + 1}
            </a>
          ))}
        </div>
      </Drawer>
    </>
  )
}
