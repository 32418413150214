import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from '@/store'
import { Picture } from '@/components/Picture'

const initialState: SliceState = {
  search: {
    data: {
      zip_code: '',
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      types_of_doors: ['interior'],
      location: 'home',
      how_many: '1',
      time_to_start: 'within 48 hours',
      insurance: 'yes',
      door_schedule: 'yes',
      about_project: '',
      masters: [],
    },
    masters: [],
    loading: false,
    errors: null,
    sent: false,
  },
  data: {
    first_name: '',
    last_name: '',
    company_name: '',
    position: '',
    phone: '',
    email: '',
    address: '',
    zip_code: '',
    zip_code_radius: 10,
    portfolio_images: null,
    license: null,
    liability_insurance: null,
    workers_compensation: null,
    past_clients_list: null,
  },
  coords: {
    data: null,
    loading: false,
    error: false,
    timeoutId: null,
  },
  loadingClosest: [],
  loading: false,
  errors: null,
  sent: false,
}

const registration = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    changeField(
      state,
      { payload }: PayloadAction<{ fieldName: keyof RegistrationFields; fieldValue: any }>,
    ) {
      const { fieldName, fieldValue } = payload
      state.data = { ...state.data, [fieldName]: fieldValue }
    },
    requestSubmit(state) {
      state.loading = true
      state.sent = false
    },
    successSubmit(state) {
      state.loading = false
      state.errors = null
      state.data = initialState.data
      state.sent = true
    },
    failureSubmit(state, { payload }: PayloadAction<ErrorType<RegistrationFields>>) {
      state.loading = false
      state.errors = payload
    },
    resetSubmit(state) {
      state.sent = false
      state.coords.data = null
    },
    requestCoordsByZip(state) {
      state.coords.loading = true
    },
    successCoordsByZip(state, { payload }: PayloadAction<CoordsByZipSuccess>) {
      state.coords.data = { lat: +payload.latitude, lng: +payload.longitude }
      state.coords.loading = false
      state.coords.error = false
    },
    failureCoordsByZip(state) {
      state.coords.data = null
      state.coords.loading = false
      state.coords.error = true
    },
    clearCoordsByZip(state) {
      state.coords.data = null
      state.coords.loading = false
      state.coords.error = false
    },
    cancelCoordsByZipRequest(state) {
      const timeoutId = state.coords.timeoutId
      if (timeoutId) clearTimeout(timeoutId)
    },
    updateCoordsByZipTimeoutId(state, { payload }: PayloadAction<number>) {
      state.coords.timeoutId = payload
    },
    requestClosest(state, { payload }: PayloadAction<string[]>) {
      state.loadingClosest = payload
    },
    successClosest(state) {
      state.loadingClosest = []
    },
    failureClosest(state) {
      state.loadingClosest = []
    },
    changeSearchField(
      state,
      { payload }: PayloadAction<{ fieldName: keyof SearchFields; fieldValue: any }>,
    ) {
      const { fieldName, fieldValue } = payload
      state.search.data = { ...state.search.data, [fieldName]: fieldValue }
    },
    requestMasters(state) {
      state.search.loading = true
    },
    successMasters(state, { payload }: PayloadAction<{ masters: Master[] }>) {
      state.search.masters = payload.masters
      state.search.loading = false
    },
    failureMasters(state) {
      state.search.loading = false
    },
    requestContactMaster(state) {
      state.search.loading = true
      state.search.sent = false
    },
    successContactMaster(state) {
      state.search.loading = false
      state.search.errors = null
      state.search.sent = true
    },
    failureContactMaster(state, { payload }: PayloadAction<ErrorType<SearchFields>>) {
      state.search.loading = false
      state.search.errors = payload
    },
    resetContactMaster(state, { payload }: PayloadAction<{ resetFields: boolean }>) {
      state.search.sent = false
      if (payload.resetFields) {
        state.search.data = initialState.search.data
      }
    },
  },
})

export const registerMaster = ({ onSuccess }: { onSuccess?: () => void } = {}): AppThunk => async (
  dispatch,
  getState,
  { api },
) => {
  try {
    dispatch(requestSubmit())
    const data = getState().registration.data
    const response: MasterRegisterSuccess | MasterRegisterError = await api.masterSignUp(data)

    if (isResultSuccess(response)) {
      dispatch(successSubmit())
      if (onSuccess) onSuccess()
    } else {
      dispatch(failureSubmit(response.errors))
    }
  } catch (err) {
    dispatch(failureSubmit({ catched: ['Server Error. Please try again later.'] }))
  }
}

export const getCoordsByZip = ({ zip }: { zip: string }): AppThunk => async (
  dispatch,
  _getState,
  { api },
) => {
  try {
    dispatch(cancelCoordsByZipRequest())

    const timeoutId = (setTimeout(async () => {
      dispatch(requestCoordsByZip())
      const params = { code: zip }
      const response: CoordsByZipSuccess | CoordsByZipError = await api.coordsByZip({ params })

      if (isCoordsByZipSuccess(response)) {
        dispatch(successCoordsByZip(response))
      } else {
        dispatch(failureCoordsByZip())
      }
    }, 250) as unknown) as number

    dispatch(updateCoordsByZipTimeoutId(timeoutId))
  } catch (err) {
    dispatch(failureCoordsByZip())
  }
}

export const findClosestMastersByZip = ({
  zip,
  onSuccess,
  onError,
}: {
  zip: string
  onSuccess?: () => void
  onError?: (tips?: string[]) => void
}): AppThunk => async (dispatch, _getState, { api }) => {
  try {
    dispatch(requestClosest([zip]))
    const params = { code: zip }
    const response: ClosestByZipSuccess | ClosestByZipError = await api.closestByZip({ params })

    if (isClosestByZipSuccess(response)) {
      dispatch(successClosest())
      if (onSuccess) onSuccess()
    } else {
      dispatch(failureClosest())
      if (onError) onError(response.closest)
    }
  } catch (err) {
    dispatch(failureClosest())
    if (onError) onError()
  }
}

export const findMastersByZip = ({
  zip,
  onSuccess,
  onError,
}: {
  zip: string
  onSuccess?: () => void
  onError?: () => void
}): AppThunk => async (dispatch, _getState, { api }) => {
  try {
    dispatch(requestMasters())
    const params = { code: zip }
    const response: MastersByZipSuccess | MastersByZipError = await api.mastersByZip({ params })

    if (isMastersByZipSuccess(response)) {
      dispatch(successMasters({ masters: response.data }))
      if (onSuccess) onSuccess()
    } else {
      dispatch(failureMasters())
      if (onError) onError()
    }
  } catch (err) {
    dispatch(failureMasters())
    if (onError) onError()
  }
}

export const sendRequestToMasters = ({
  code,
  onSuccess,
}: {
  code: string
  onSuccess?: () => void
}): AppThunk => async (dispatch, getState, { api }) => {
  try {
    dispatch(requestContactMaster())
    const data = getState().registration.search.data
    const response: MasterContactSuccess | MasterContactError = await api.contactMaster({
      data: { ...data, zip_code: code },
    })

    if (isMasterContactResultSuccess(response)) {
      dispatch(successContactMaster())
      if (onSuccess) onSuccess()
    } else {
      dispatch(failureContactMaster(response.errors))
    }
  } catch (err) {
    dispatch(failureContactMaster({ catched: ['Server Error. Please try again later.'] }))
  }
}

export const countMasterPhoneClick = ({ id }: { id: number }): AppThunk => async (
  _dispatch,
  _getState,
  { api },
) => {
  try {
    await api.masterPhoneClick({ data: { master_id: id } })
  } catch (err) {}
}

export const resetContactMasterState = (resetFields?: boolean): AppThunk => async dispatch => {
  dispatch(resetContactMaster({ resetFields: resetFields || false }))
}

export const getForm = createSelector(
  (state: RootState) => state.registration,
  registration => registration,
)

export const getSearchForm = createSelector(
  (state: RootState) => state.registration,
  registration => registration.search,
)

export const getClosestState = createSelector(
  (state: RootState) => state.registration,
  registration => {
    const { loadingClosest } = registration
    return { loading: loadingClosest }
  },
)

export const {
  changeField,
  requestSubmit,
  successSubmit,
  failureSubmit,
  resetSubmit,
  requestCoordsByZip,
  successCoordsByZip,
  failureCoordsByZip,
  clearCoordsByZip,
  cancelCoordsByZipRequest,
  updateCoordsByZipTimeoutId,
  requestClosest,
  failureClosest,
  successClosest,
  changeSearchField,
  requestMasters,
  successMasters,
  failureMasters,
  requestContactMaster,
  successContactMaster,
  failureContactMaster,
  resetContactMaster,
} = registration.actions

export default registration.reducer

// <-- Types --> //

export interface RegistrationFields {
  first_name: string
  last_name: string
  company_name: string
  position: string
  phone: string
  email: string
  address: string
  zip_code: string
  zip_code_radius: number
  portfolio_images: File[] | null
  license: File | null
  liability_insurance: File | null
  workers_compensation: File | null
  past_clients_list: File | null
}

export type TypeOfDoors = 'interior' | 'exterior'
export type Location = 'home' | 'business'
export type HowMany = '1' | '2' | '3' | '4 or more'
export type TimeToStart =
  | 'within 48 hours'
  | 'within a week'
  | 'within a month'
  | 'within a year'
  | 'flexible'
  | 'not sure'
export type Insurance = 'yes' | 'no'
export type DoorSchedule = 'yes' | 'no'
export type AboutProject = string

export interface SearchFields {
  zip_code: string
  first_name: string
  last_name: string
  phone: string
  email: string
  types_of_doors: TypeOfDoors[]
  location: Location
  how_many: HowMany
  time_to_start: TimeToStart
  insurance: Insurance
  door_schedule: DoorSchedule
  about_project: AboutProject
  masters: number[]
}

export type FileType = {
  file: string
}

export interface Master {
  id: number
  first_name: string
  last_name: string
  phone: string
  images: Picture[]
  rating: number
  files: FileType[]
}

type SliceState = {
  search: {
    data: SearchFields
    loading: boolean
    errors: ErrorType<SearchFields> | null
    masters: Master[]
    sent: boolean
  }
  data: RegistrationFields
  loading: boolean
  errors: ErrorType<RegistrationFields> | null
  sent: boolean
  coords: {
    data: {
      lat: number
      lng: number
    } | null
    timeoutId: number | null
    loading: boolean
    error: boolean
  }
  loadingClosest: string[]
}

type ClosestByZipSuccess = {
  result: 'success'
}

type ClosestByZipError = {
  result: 'success' | 'error'
  closest?: string[]
}

function isClosestByZipSuccess(
  response: ClosestByZipSuccess | ClosestByZipError,
): response is ClosestByZipSuccess {
  return response.result === 'success' && !Object.keys(response).includes('closest')
}

type MastersByZipSuccess = {
  result: 'success'
  data: Master[]
}

type MastersByZipError = {
  result: 'success' | 'error'
  data?: Master[]
}

function isMastersByZipSuccess(
  response: MastersByZipSuccess | MastersByZipError,
): response is MastersByZipSuccess {
  return response.result === 'success' && !!response.data && response.data?.length > 0
}

type MasterRegisterSuccess = {
  result: 'success'
}

type MasterRegisterError = {
  result: 'error'
  errors: ErrorType<RegistrationFields>
}

export type ErrorFieldKey<T> = keyof (T & { catched: 'string' })

export type ErrorType<T> = {
  [key in ErrorFieldKey<T>]?: string[]
}

function isResultSuccess(
  response: MasterRegisterSuccess | MasterRegisterError,
): response is MasterRegisterSuccess {
  return response.result === 'success'
}

type CoordsByZipSuccess = {
  result: 'success'
  latitude: string
  longitude: string
}

type CoordsByZipError = {
  result: 'error'
}

function isCoordsByZipSuccess(
  response: CoordsByZipSuccess | CoordsByZipError,
): response is CoordsByZipSuccess {
  return response.result === 'success'
}

type MasterContactSuccess = {
  result: 'success'
}

type MasterContactError = {
  result: 'error'
  errors: ErrorType<SearchFields>
}

function isMasterContactResultSuccess(
  response: MasterContactSuccess | MasterContactError,
): response is MasterContactSuccess {
  return response.result === 'success'
}
