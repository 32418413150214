import { CSSTransition } from 'react-transition-group'
import React, { useEffect, useRef } from 'react'
import { Portal, Icon } from '@/components'
import { ModalProps } from './interfaces'
import styles from './Modal.module.scss'
import noScroll from 'no-scroll'
import cn from 'classnames'

export default function Modal({
  children,
  visible = false,
  header,
  closableOverlay = true,
  onClose,
}: ModalProps) {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (visible) {
      noScroll.on()
    } else {
      noScroll.off()
    }
  }, [visible])

  const close = () => {
    noScroll.off()
    onClose()
  }

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (closableOverlay) close()
  }

  return (
    <CSSTransition
      in={visible}
      timeout={{
        appear: 0,
        enter: 100,
        exit: 100,
      }}
      classNames={{
        enter: styles['on-enter'],
        enterActive: styles['on-enter-active'],
        enterDone: styles['on-enter-done'],
        exit: styles['on-exit'],
        exitActive: styles['on-exit-active'],
      }}
      unmountOnExit
    >
      <Portal>
        <div
          ref={ref}
          onClick={onClick}
          className={cn(styles.modal, { [styles.closable]: closableOverlay })}
        >
          <button type="button" className={styles.close} onClick={close}>
            <Icon name="close-big" />
          </button>
          <div className={styles.header}>{header}</div>
          <div className={styles.content}>{children}</div>
        </div>
      </Portal>
    </CSSTransition>
  )
}
