import { getForm } from '@/store/slices/registrationSlice'
import { CSSTransition } from 'react-transition-group'
import { Circle, Marker } from '@react-google-maps/api'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Map, Icon } from '@/components'
import styles from './Map.module.scss'

const milesToMeters = (miles: number) => {
  return miles * 1609.34
}

const defaultCenter = {
  lat: 40.7081117,
  lng: -74.02072,
}

const circleOptions = {
  strokeColor: '#4A4CE2',
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: '#4a4ce2',
  fillOpacity: 0.2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
}

const MyMap = () => {
  const { data, coords } = useSelector(getForm)
  const { data: center, error } = coords
  const { zip_code_radius } = data

  const [circle, setCircle] = useState<google.maps.Circle | null>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [mapCenter, setMapCenter] = useState(defaultCenter)

  useEffect(() => {
    if (center) setMapCenter(center)
  }, [center])

  const onRadiusChanged = () => {
    const bounds = circle?.getBounds()
    if (bounds && map) map.fitBounds(bounds, 20)
  }

  return (
    <div className={styles.map}>
      <CSSTransition
        in={error}
        timeout={{
          appear: 0,
          enter: 160,
          exit: 160,
        }}
        classNames={{
          enter: styles['on-enter'],
          enterActive: styles['on-enter-active'],
          exit: styles['on-exit'],
          exitActive: styles['on-exit-active'],
        }}
        unmountOnExit
      >
        <div className={styles.error}>
          <div className={styles.error_inner}>
            <div className={styles.error_icon}>
              <Icon name="quote" />
            </div>
            Sorry, ZIP code associated coordinates was not found!
          </div>
        </div>
      </CSSTransition>

      <Map center={mapCenter} onLoad={map => setMap(map)}>
        {zip_code_radius > 0 && center && (
          <>
            <Circle
              center={center}
              radius={milesToMeters(zip_code_radius)}
              options={circleOptions}
              onLoad={circle => setCircle(circle)}
              onRadiusChanged={onRadiusChanged}
            />
            <Marker position={center} icon="/images/marker.svg" />
          </>
        )}
      </Map>
    </div>
  )
}

export default MyMap
