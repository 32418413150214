import { Layout, MetaData, Container, Title, Text, Paragraph, Link } from '@/components'
import { getForm } from '@/store/slices/registrationSlice'
import styles from './Registration.module.scss'
import { useSelector } from 'react-redux'
import Form from './Form'
import Map from './Map'

export default function RegistrationPage() {
  return (
    <Layout type="halved">
      {{
        mainContent: <MainContent />,
        sideContent: <Map />,
      }}
    </Layout>
  )
}

function MainContent() {
  const { sent } = useSelector(getForm)

  return (
    <>
      <MetaData title="Installer Sign Up" />

      <Container size="halved" className={styles.wrapper}>
        {sent ? (
          <section className={styles.success}>
            <Title size="medium" align="center">
              Your request was successfully <Text mark>sent!</Text>
            </Title>

            <Paragraph align="center">
              We will contact you soon. {'\n'}Thank you for using our service!
            </Paragraph>

            <Link href="/">Back to Home Page</Link>
          </section>
        ) : (
          <>
            <Title>
              Installer <Text mark>Sign Up</Text>
            </Title>

            <Form />
          </>
        )}
      </Container>
    </>
  )
}
