import { CSSTransition } from 'react-transition-group'
import { FormFieldProps } from './interfaces'
import { useState, useEffect } from 'react'
import styles from './FormField.module.scss'

export default function FormField({
  label,
  labelFor,
  description,
  optional = false,
  error,
  className,
  children,
}: FormFieldProps) {
  const [errorText, setErrorText] = useState(error)

  useEffect(() => {
    if (error) setErrorText(error)
  }, [error])

  const hasError = !!error

  return (
    <div className={className}>
      {label && (
        <label htmlFor={labelFor} className={styles.label}>
          {label}
          {optional && <span className={styles.optional}>(optional)</span>}
        </label>
      )}

      {description && <div className={styles.description}>{description}</div>}

      <div className={styles.field}>
        {children}

        <CSSTransition
          in={hasError}
          timeout={{
            appear: 0,
            enter: 160,
            exit: 160,
          }}
          classNames={{
            enter: styles['on-enter'],
            enterActive: styles['on-enter-active'],
            exit: styles['on-exit'],
            exitActive: styles['on-exit-active'],
          }}
          unmountOnExit
        >
          <div className={styles.error}>{errorText}</div>
        </CSSTransition>
      </div>
    </div>
  )
}
