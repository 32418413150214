import {
  Button,
  Input,
  Form,
  FormField,
  Icon,
  MaskedPhoneInput,
  InputNumber,
  Upload,
} from '@/components'
import {
  changeField,
  getForm,
  getCoordsByZip,
  clearCoordsByZip,
  RegistrationFields,
  registerMaster,
  ErrorType,
} from '@/store/slices/registrationSlice'
import React, { useRef, createRef, useCallback, useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styles from './Form.module.scss'
import findPos from '@/helpers/findPos'
import YMTraker from '@/metrics/YM'
import GTraker from '@/metrics/Gtag'

export default function RegistrationForm() {
  const { data, errors: formErrors, loading } = useSelector(getForm)

  const refs = useRef(
    Object.keys(data).reduce((acc, key) => {
      acc[key] = createRef<HTMLInputElement>()
      return acc
    }, {} as RefsObject),
  )

  const [errors, setErrors] = useState<ErrorType<RegistrationFields> | null>(formErrors)
  const dispatch = useDispatch()

  useEffect(() => {
    setErrors(formErrors)

    const errorKeys = Object.keys(formErrors || {})

    if (errorKeys.length > 0) {
      let firstFieldWithError = Object.keys(data).find(key =>
        errorKeys.some(errorKey => errorKey === key),
      )

      if (firstFieldWithError) {
        const field = refs.current[firstFieldWithError].current
        if (field) {
          const positionY = findPos(field) - 120
          window.scrollTo(0, positionY)
          field.focus()
        }
      }
    }
  }, [formErrors])

  const onFieldChange = useCallback(
    (name: keyof RegistrationFields) => (value: any) => {
      dispatch(changeField({ fieldName: name, fieldValue: value }))

      if (name === 'zip_code') {
        if (value.length >= 5) {
          dispatch(getCoordsByZip({ zip: value }))
        } else {
          dispatch(clearCoordsByZip())
        }
      }

      setErrors(prev => {
        if (!prev) return prev

        const errors = { ...prev }
        delete errors[name]
        return Object.keys(errors).length > 0 ? errors : null
      })
    },
    [],
  )

  const changeFunctions = useMemo(() => getOnChangeFunctions(data, onFieldChange), [])

  const onFormSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    dispatch(
      registerMaster({
        onSuccess: () => {
          YMTraker.registration()
          GTraker.registration()
        },
      }),
    )
  }

  return (
    <Form onSubmit={onFormSubmit}>
      <Form.Row>
        <FormField
          label="First Name"
          labelFor="installer_first_name"
          error={errors?.first_name?.[0]}
        >
          <Input
            id="installer_first_name"
            ref={refs.current.first_name}
            value={data.first_name}
            onChange={changeFunctions['first_name']}
            danger={!!errors?.first_name}
          />
        </FormField>

        <FormField label="Last Name" labelFor="installer_last_name" error={errors?.last_name?.[0]}>
          <Input
            id="installer_last_name"
            ref={refs.current.last_name}
            value={data.last_name}
            onChange={changeFunctions['last_name']}
            danger={!!errors?.last_name}
          />
        </FormField>
      </Form.Row>

      <Form.Row>
        <FormField
          label="Company Name"
          labelFor="installer_company_name"
          error={errors?.company_name?.[0]}
          optional
        >
          <Input
            id="installer_company_name"
            ref={refs.current.company_name}
            value={data.company_name}
            onChange={changeFunctions['company_name']}
            danger={!!errors?.company_name}
          />
        </FormField>

        <FormField
          label="Position"
          labelFor="installer_position"
          error={errors?.position?.[0]}
          optional
        >
          <Input
            id="installer_position"
            ref={refs.current.position}
            value={data.position}
            onChange={changeFunctions['position']}
            danger={!!errors?.position}
          />
        </FormField>
      </Form.Row>

      <Form.Row>
        <FormField label="Phone" labelFor="installer_phone" error={errors?.phone?.[0]}>
          <MaskedPhoneInput
            id="installer_phone"
            ref={refs.current.phone}
            value={data.phone}
            onChange={changeFunctions['phone']}
            danger={!!errors?.phone}
          />
        </FormField>

        <FormField label="Email" labelFor="installer_email" error={errors?.email?.[0]}>
          <Input
            id="installer_email"
            ref={refs.current.email}
            value={data.email}
            inputMode="email"
            onChange={changeFunctions['email']}
            danger={!!errors?.email}
          />
        </FormField>
      </Form.Row>

      <Form.Row>
        <FormField label="Address" labelFor="installer_address" error={errors?.address?.[0]}>
          <Input
            id="installer_address"
            ref={refs.current.address}
            value={data.address}
            onChange={changeFunctions['address']}
            danger={!!errors?.address}
          />
        </FormField>
      </Form.Row>

      <Form.Row>
        <FormField
          label="Portfolio images"
          labelFor="portfolio_images"
          description="Images, 8MB file limit, 10 files allowed."
          error={errors?.portfolio_images?.join(' ')}
        >
          <Upload
            id="portfolio_images"
            ref={refs.current.portfolio_images}
            onChange={changeFunctions['portfolio_images']}
            danger={!!errors?.portfolio_images}
            multiple
            maxCount={10}
            accept="image/png, image/jpeg, image/jpg"
          />
        </FormField>
      </Form.Row>

      <Form.Row>
        <FormField
          label="License"
          labelFor="installer_license"
          description="Image or document, 200MB file limit, one file allowed."
          error={errors?.license?.[0]}
        >
          <Upload
            id="installer_license"
            ref={refs.current.license}
            onChange={changeFunctions['license']}
            danger={!!errors?.license}
          />
        </FormField>
      </Form.Row>

      <Form.Row>
        <FormField
          label="General liability insurance"
          labelFor="installer_insurance"
          description="Image or document, 200MB file limit, one file allowed."
          error={errors?.liability_insurance?.[0]}
        >
          <Upload
            id="installer_insurance"
            ref={refs.current.liability_insurance}
            onChange={changeFunctions['liability_insurance']}
            danger={!!errors?.liability_insurance}
          />
        </FormField>
      </Form.Row>

      <Form.Row>
        <FormField
          label="Workers’ compensation"
          labelFor="installer_compensation"
          description="Image or document, 200MB file limit, one file allowed."
          error={errors?.workers_compensation?.[0]}
        >
          <Upload
            id="installer_compensation"
            ref={refs.current.workers_compensation}
            onChange={changeFunctions['workers_compensation']}
            danger={!!errors?.workers_compensation}
          />
        </FormField>
      </Form.Row>

      <Form.Row>
        <FormField
          label="List of past clients"
          labelFor="installer_past_clients_list"
          description="Image or document, 200MB file limit, one file allowed."
          error={errors?.past_clients_list?.[0]}
        >
          <Upload
            id="installer_past_clients_list"
            ref={refs.current.past_clients_list}
            onChange={changeFunctions['past_clients_list']}
            danger={!!errors?.past_clients_list}
          />
        </FormField>
      </Form.Row>

      <Form.Divider />

      <Form.Row>
        <div className={styles.zip_code_icon}>
          <Icon name="distance" />
          <span className={styles.zip_code_text}>Working Area</span>
        </div>
      </Form.Row>

      <Form.Row>
        <div className={styles.zip_code_wrapper}>
          <FormField
            label="ZIP code"
            labelFor="installer_zip_code"
            className={styles.zip_code}
            error={errors?.zip_code?.[0]}
          >
            <Input
              id="installer_zip_code"
              ref={refs.current.zip_code}
              value={data.zip_code}
              placeholder="07065"
              inputMode="numeric"
              onChange={changeFunctions['zip_code']}
              danger={!!errors?.zip_code}
            />
          </FormField>
        </div>
      </Form.Row>

      <Form.Row>
        <div className={styles.miles_count_wrapper}>
          <FormField
            label="Preferred working area around ZIP code"
            labelFor="installer_zip_code_radius"
            error={errors?.zip_code_radius?.[0]}
            className={styles.miles_count}
          >
            <InputNumber
              id="installer_zip_code_radius"
              ref={refs.current.zip_code_radius}
              value={data.zip_code_radius}
              onChange={changeFunctions['zip_code_radius']}
              danger={!!errors?.zip_code_radius}
              max={100}
            />
          </FormField>

          <div className={styles.miles_count_text}>miles</div>
        </div>
      </Form.Row>

      <Form.Footer>
        <Button size="large" color="decor" variant="primary" disabled={loading}>
          Send
        </Button>
      </Form.Footer>
    </Form>
  )
}

type RefsObject = {
  [key: string]: React.RefObject<HTMLInputElement>
}

type OnChangeFunctionsCb = (name: keyof RegistrationFields) => (value: any) => void
type OnChangeFunctionsResult = {
  [key in keyof RegistrationFields]: (value: any) => void
}

function getOnChangeFunctions(
  fields: RegistrationFields,
  fn: OnChangeFunctionsCb,
): OnChangeFunctionsResult {
  const items = Object.keys(fields) as Array<keyof RegistrationFields>
  return items.reduce<OnChangeFunctionsResult>((acc, key) => {
    acc[key] = fn(key)
    return acc
  }, {} as OnChangeFunctionsResult)
}
