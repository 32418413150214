import { memo, forwardRef, useRef, useCallback, ChangeEventHandler } from 'react'
import { RadioIconProps } from './interfaces'
import styles from './Choice.module.scss'
import mergeRefs from 'react-merge-refs'
import { Icon } from '@/components'
import cn from 'classnames'

const RadioIcon = memo(
  forwardRef((props: RadioIconProps, inputRef) => {
    const { value, checked, onChange, disabled = false, title, name, icon, ...rest } = props
    const ref = useRef<HTMLInputElement>(null)

    const _onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      e => {
        onChange(e.target.value)
      },
      [onChange],
    )

    return (
      <label className={cn(styles.choice, styles.radio_icon)} {...rest}>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={_onChange}
          disabled={disabled}
          className={styles.input}
          ref={mergeRefs([ref, inputRef])}
        />

        <span className={styles.title}>
          <span className={styles.icon}>
            <Icon name={icon} />
          </span>

          {title}
        </span>
      </label>
    )
  }),
)

export default RadioIcon
