export const GA_TRACKING_ID = 'G-Y3FD5YW838'

const isProductionMode = process.env.NODE_ENV === 'production'

const pageview = url => {
  try {
    if (isProductionMode) {
      window.gtag('config', GA_TRACKING_ID, {
        page_path: url,
      })
    }
  } catch (error) {}
}

const trackGoal = goal => params => {
  try {
    if (isProductionMode) {
      gtag('event', goal, params)
    }
  } catch (e) {
    console.error('Gtag trackGoal exception', e)
  }
}

export default {
  pageview,
  registration: trackGoal('registration'),
  request: trackGoal('request'),
}
