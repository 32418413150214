import {
  useState,
  useEffect,
  useRef,
  ChangeEventHandler,
  MouseEventHandler,
  forwardRef,
} from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { DragAndDrop, Icon } from '@/components'
import styles from './Upload.module.scss'
import { UploadProps } from './interfaces'
import mergeRefs from 'react-merge-refs'
import cn from 'classnames'

const Upload = forwardRef(
  ({ multiple = false, onChange, danger = false, id, maxCount, accept }: UploadProps, blockRef) => {
    const [uploads, setUploads] = useState<File[]>([])
    const hasUploads = uploads.length > 0
    const uploadDisabled = !multiple ? hasUploads : maxCount ? uploads.length >= maxCount : false
    const uploadRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
      if (multiple) {
        onChange(uploads)
      } else {
        onChange(uploads?.[0] ?? null)
      }
    }, [uploads])

    const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
      const newUploads = Array.from(e.target.files || [])
      let mergedUploads = [...uploads, ...newUploads]
      if (maxCount === 1) {
        mergedUploads = mergedUploads.slice(-1)
      } else if (maxCount) {
        mergedUploads = mergedUploads.slice(0, maxCount)
      }

      setUploads(mergedUploads)
    }

    const handleDrop = (files: File[]) => {
      setUploads(files)
    }

    const handleClick: MouseEventHandler<HTMLInputElement> = e => {
      const target = e.target as HTMLInputElement
      target.value = ''
    }

    const handleDragAndDropClick = () => {
      if (inputRef.current) inputRef.current.click()
    }

    const removeFile = (name: string) => () => {
      const removedUploads = uploads.filter(item => item.name !== name)
      setUploads(removedUploads)
    }

    return (
      <div
        className={cn(styles.upload, { [styles.error]: danger, [styles.disabled]: uploadDisabled })}
        ref={mergeRefs([blockRef, uploadRef])}
      >
        <DragAndDrop
          onDrop={handleDrop}
          onClick={handleDragAndDropClick}
          component={DragAndDropButton}
          draggingClassName={styles.dragging}
        >
          <input
            id={id}
            ref={inputRef}
            className={styles.input}
            type="file"
            name="files"
            onChange={handleChange}
            hidden
            onClick={handleClick}
            disabled={uploadDisabled}
            multiple={multiple}
            accept={accept}
          />
          <Icon name="upload" />
          <span>
            Drag your file here or <mark>browse</mark> to upload
          </span>
        </DragAndDrop>

        {uploads.length > 0 && (
          <ul className={styles.previews}>
            <TransitionGroup component={null}>
              {uploads.map((upload, ind) => {
                return (
                  <CSSTransition
                    key={upload.name || ind}
                    in={true}
                    timeout={{
                      appear: 0,
                      enter: 230,
                      exit: 230,
                    }}
                    classNames="on"
                    unmountOnExit
                  >
                    <li className={styles.preview}>
                      <Icon name="file" />
                      <div title={upload.name} className={styles.name}>
                        {upload.name}
                      </div>
                      <button
                        className={styles.remove}
                        type="button"
                        onClick={removeFile(upload.name)}
                      />
                    </li>
                  </CSSTransition>
                )
              })}
            </TransitionGroup>
          </ul>
        )}
      </div>
    )
  },
)

export default Upload

const DragAndDropButton: React.FC<{ className?: string }> = forwardRef(
  ({ className, ...props }, ref) => {
    const divRef = useRef<HTMLDivElement>(null)
    return (
      <div className={cn(styles.button, className)} ref={mergeRefs([ref, divRef])} {...props} />
    )
  },
)
