import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '15',
  height = '18',
  viewBox = '0 0 15 18',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 7.5h5.232l.003.048c.008.105.015.21.015.316v6.386A3.754 3.754 0 0111.25 18h-7.5A3.755 3.755 0 010 14.25V3.75A3.755 3.755 0 013.75 0h3.386c.106 0 .211.007.316.015L7.5.018V5.25A2.25 2.25 0 009.75 7.5zm4.905-1.499H9.75a.75.75 0 01-.75-.75V.346a5.233 5.233 0 011.848 1.193l2.613 2.614a5.215 5.215 0 011.194 1.848z"
    />
  </svg>
)

export default SVG
