import { changeSearchField, getSearchForm, AboutProject } from '@/store/slices/registrationSlice'
import { useSelector, useDispatch } from 'react-redux'
import { Title, Text, TextArea } from '@/components'
import styles from '../SearchPage.module.scss'

const fieldName = 'about_project'

export default function StepSeven() {
  const dispatch = useDispatch()

  const { data } = useSelector(getSearchForm)
  const value = data[fieldName]

  const onFieldChange = (value: AboutProject) => {
    dispatch(changeSearchField({ fieldName: fieldName, fieldValue: value }))
  }

  return (
    <div className={styles.content}>
      <Title size="medium" align="center">
        Please tell us <Text mark>a little</Text> about your project
      </Title>

      <TextArea
        value={value}
        onChange={onFieldChange}
        placeholder="Enter project description, if you wish"
      />
    </div>
  )
}
