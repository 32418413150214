import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useNotification } from '@/contexts/NotificationContext'
import styles from './Notifications.module.scss'
import Notification from './Notification'

export default function NotificationHandler() {
  const notification = useNotification()
  const { list, close } = notification

  return (
    <div className={styles.notifications}>
      <div className={styles.list}>
        <TransitionGroup component={null}>
          {list.map((item, ind) => {
            const id = item.id
            return (
              <CSSTransition
                in={true}
                key={ind}
                timeout={{
                  appear: 0,
                  enter: 200,
                  exit: 200,
                }}
                classNames={{
                  enter: styles['on-enter'],
                  enterActive: styles['on-enter-active'],
                  exit: styles['on-exit'],
                  exitActive: styles['on-exit-active'],
                }}
              >
                <Notification {...item} onClose={() => close(id)} />
              </CSSTransition>
            )
          })}
        </TransitionGroup>
      </div>
    </div>
  )
}
