import { countMasterPhoneClick, Master as MasterType } from '@/store/slices/registrationSlice'
import { Button, Icon, Picture, GalleryWrapper } from '@/components'
import styles from './Master.module.scss'
import { useCallback } from 'react'
import Rating from './Rating'
import Certificates from './Certificates'
import { useDispatch } from 'react-redux'

interface MasterProps extends MasterType {
  onClick: (id: number) => void
}

export default function Master({
  id,
  first_name,
  last_name,
  phone,
  images,
  rating,
  files,
  onClick,
}: MasterProps) {
  const photosMax = 1
  const photosRest = images.length - photosMax
  const masterName = `${first_name} ${last_name}`
  const dispatch = useDispatch()

  const _onClick = useCallback(() => {
    onClick(id)
  }, [id])

  const _onPhoneClick = useCallback(() => {
    dispatch(countMasterPhoneClick({ id }))
  }, [id])

  return (
    <div className={styles.master}>
      <div className={styles.info}>
        <div className={styles.name}>{masterName}</div>
        <div className={styles.phone}>
          <a href={`tel:${phone}`} onClick={_onPhoneClick}>
            {phone}
          </a>
        </div>

        {rating > 0 && (
          <div className={styles.rating}>
            <Rating
              value={rating}
              tip="Rating based on customer's reviews and evaluation by our specialists."
            />
          </div>
        )}
      </div>

      <div className={styles.media}>
        {images?.length > 0 && (
          <div className={styles.photos}>
            {images.slice(0, photosMax).map((pic, ind) => (
              <GalleryWrapper
                key={ind}
                initialSlide={ind}
                name={masterName}
                images={images}
                className={styles.photo}
                {...(photosRest > 0 && { 'data-count': `+${photosRest}` })}
              >
                <Picture {...pic} />
              </GalleryWrapper>
            ))}
          </div>
        )}

        {files?.length > 0 && <Certificates files={files} />}
      </div>

      <div className={styles.button}>
        <Button color="decor" onClick={_onClick}>
          <span className={styles.icon}>
            <Icon name="quote" />
          </span>
          Get a Quote
        </Button>
      </div>
    </div>
  )
}
