import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '23',
  height = '19',
  viewBox = '0 0 23 19',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.327 10.998c.024 0 .049-.025.073-.05H10.3c0-.015.003-.032.01-.048l.017.098zm0 0L7.76 13.54l2.567-2.542zm-5.981-2.66l.003-.002h.011l.02-.01c.868-.41 1.831-.65 2.891-.65a.59.59 0 00.589-.59.59.59 0 00-.589-.588 8.11 8.11 0 00-2.557.422c.182-2.678 2.778-4.842 5.98-4.842 3.308 0 5.986 2.315 5.986 5.13v1.1a.59.59 0 00.589.59.59.59 0 00.589-.59v-.226c1.088.17 1.968.814 2.582 1.631.636.847.982 1.871.982 2.727 0 2.783-1.937 4.764-4.642 4.764h-3.593a.59.59 0 00-.59.59.59.59 0 00.59.588h3.617c3.31 0 5.796-2.565 5.796-5.942 0-1.248-.523-2.64-1.442-3.707-.874-1.045-2.036-1.688-3.3-1.834v-.062c0-.042 0-.104-.027-.168C17.5 3.44 14.42.9 10.693.9c-3.855 0-7.04 2.72-7.14 6.135h0V7.468C1.673 8.538.4 10.388.4 12.513c0 3.246 2.918 5.77 6.672 5.894h1.055a.59.59 0 00.589-.59.59.59 0 00-.59-.588H7.32c-3.24 0-5.742-2.1-5.742-4.716 0-1.758 1.113-3.288 2.719-4.157a.165.165 0 00.02-.006c.012-.004.022-.01.028-.012h0zm6.1 2.441v.014a.152.152 0 00-.024.018.113.113 0 00-.03.018.126.126 0 00-.08.066l-.003.003h-.023l-.03.029-2.566 2.542h0a.573.573 0 000 .826.573.573 0 00.825 0l1.54-1.54v4.94a.59.59 0 00.59.59.59.59 0 00.588-.59v-4.94l1.54 1.54a.583.583 0 00.414.176c.15 0 .297-.06.413-.176a.573.573 0 000-.826l-2.543-2.542-.001-.002c-.011-.01-.028-.027-.046-.04a.188.188 0 00-.046-.027l-.004-.004-.03-.03h-.014a.127.127 0 00-.057-.023l-.027-.025h-.024l-.012-.012-.022-.008a.404.404 0 00-.13-.023.405.405 0 00-.129.023l-.068.023zm-.022.145v-.073h0v.073zm.449-.109h0zm-.05-.024h0z"
      fill={fill}
      stroke={fill}
      strokeWidth=".2"
    />
  </svg>
)

export default SVG
