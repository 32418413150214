import Swiper, { ReactIdSwiperProps, SwiperRefNode } from 'react-id-swiper'
import { useState, useEffect, useRef, useCallback } from 'react'
import { Picture, Modal } from '@/components'
import { GalleryProps } from './interfaces'
import styles from './Gallery.module.scss'

const params: ReactIdSwiperProps = {
  speed: 160,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  resistanceRatio: 0,
  slidesPerView: 1,
  keyboard: true,
  watchOverflow: true,
  watchSlidesVisibility: true,
  preloadImages: false,
  effect: 'fade',
}

export default function Gallery({
  initialIndex = 0,
  images,
  name,
  description,
  ...modalProps
}: GalleryProps) {
  const swiperRef = useRef<SwiperRefNode>(null)
  const [activeInd, setActiveInd] = useState(initialIndex)

  const onSlideChange = useCallback(() => {
    const activeInd = swiperRef?.current?.swiper?.activeIndex ?? 0
    setActiveInd(activeInd)
  }, [])

  useEffect(() => {
    setActiveInd(initialIndex)
  }, [initialIndex])

  useEffect(() => {
    const swiperInstance = swiperRef?.current?.swiper

    if (swiperInstance) {
      swiperInstance.on('slideChange', onSlideChange)
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off('slideChange', onSlideChange)
      }
    }
  })

  const header = (
    <div className={styles.header}>
      <div className={styles.pagination}>
        {activeInd + 1}/{images.length}
      </div>
      <div>
        <div className={styles.name}>{name}</div>
        <div>{description}</div>
      </div>
    </div>
  )

  return (
    <Modal {...modalProps} header={header}>
      <div className={styles.gallery}>
        <Swiper ref={swiperRef} initialSlide={initialIndex} {...params}>
          {images.map((image, ind) => {
            return (
              <div key={ind}>
                <div className={styles.img}>
                  <Picture {...image} />
                </div>
              </div>
            )
          })}
        </Swiper>
      </div>
    </Modal>
  )
}
