import { GoogleMap, LoadScriptNext } from '@react-google-maps/api'
import { MapProps } from './interfaces'
import styles from './Map.module.scss'
import { memo } from 'react'

const API_KEY = 'AIzaSyDPIviMXy0LaGgYmYjskLg017hNj1rRn7M'

const mapOptions = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  scrollwheel: false,
}

const Map = memo(({ onLoad, center, children }: MapProps) => {
  return (
    <LoadScriptNext googleMapsApiKey={API_KEY} loadingElement={<LoadingElement />}>
      <GoogleMap
        mapContainerClassName={styles.map}
        center={center}
        zoom={11}
        options={mapOptions}
        onLoad={onLoad}
      >
        {/* Child components, such as markers, info windows, etc. */}
        {children}
      </GoogleMap>
    </LoadScriptNext>
  )
})

const LoadingElement = () => null

export default Map
