import { IconProps } from './interfaces'

const SVG = ({ fill = 'none', width = '79', height = '43', viewBox = '0 0 79 43' }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M37.303 13.264h38.79v14.05H39.657" fill="#FD5D65" />
    <path d="M39.655 18.013H5.184v23.255h34.471V18.013z" fill="#F2F2F2" />
    <path d="M39.655 18.013H5.184v3.723h34.471v-3.723z" fill="#CCC" />
    <path d="M74.129 27.315v13.952H39.657V27.315" fill="#DCEBEF" />
    <path d="M74.129 27.315v4.259H39.657v-4.26" fill="#CCC" />
    <path d="M13.714 8.554V2.749h-5.59v10.068" fill="#FEC95B" />
    <path d="M43.529 18.013l-21.11-16.1-21.108 16.1h42.218z" fill="#FD5D65" />
    <path
      d="M18.841 24.095h-6.797v8.944h6.797v-8.944zM32.793 24.095h-6.797v8.944h6.797v-8.944z"
      fill="#FEC95B"
    />
    <path d="M22.418 13.738a2.862 2.862 0 100-5.724 2.862 2.862 0 000 5.724z" fill="#515C6D" />
    <path
      d="M18.873 22.743h-6.798c-.74 0-1.341.601-1.341 1.342v8.944c0 .74.6 1.342 1.341 1.342h6.798c.74 0 1.341-.601 1.341-1.342v-8.944c0-.74-.6-1.342-1.341-1.342zm-1.342 8.944h-4.114v-6.26h4.114v6.26zM32.825 22.743h-6.797c-.741 0-1.342.601-1.342 1.342v8.944c0 .74.6 1.342 1.342 1.342h6.797c.741 0 1.342-.601 1.342-1.342v-8.944c0-.74-.601-1.342-1.342-1.342zm-1.342 8.944H27.37v-6.26h4.114v6.26zM22.45 6.662a4.208 4.208 0 00-4.203 4.204 4.208 4.208 0 004.204 4.203 4.208 4.208 0 004.203-4.203 4.209 4.209 0 00-4.203-4.204zm0 5.724c-.838 0-1.52-.682-1.52-1.52 0-.839.682-1.52 1.52-1.52.84 0 1.521.681 1.521 1.52 0 .838-.682 1.52-1.52 1.52zM70.51 30.733H43.336v2.684H70.51v-2.684zM70.51 35.027H43.336v2.683H70.51v-2.683z"
      fill="#000"
    />
    <path
      d="M77.658 39.916h-2.157v-11.27h.622c.741 0 1.342-.6 1.342-1.341V13.254c0-.741-.6-1.342-1.342-1.342H37.786L23.264.837a1.342 1.342 0 00-1.627 0l-6.55 4.996V2.74c0-.74-.601-1.341-1.342-1.341h-5.59c-.741 0-1.342.6-1.342 1.341v9.404L.528 16.936a1.341 1.341 0 00.814 2.409h2.53v20.571H1.46a1.342 1.342 0 000 2.683h76.198a1.342 1.342 0 000-2.683zm-2.876-25.32v11.367H41.029v-6.618h2.53a1.341 1.341 0 00.814-2.409l-3.07-2.34h33.479zM9.496 4.08h2.907V7.88l-2.907 2.216V4.081zm12.955-.49l17.137 13.07H5.313l17.138-13.07zM6.556 39.916V19.345h31.79v20.57H6.555zm34.473 0v-11.27h31.789v11.27H41.029z"
      fill="#000"
    />
  </svg>
)

export default SVG
