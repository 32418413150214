import { forwardRef, useRef, memo, useCallback, MouseEventHandler, ChangeEventHandler } from 'react'
import { InputNumberProps } from './interfaces'
import styles from './Input.module.scss'
import mergeRefs from 'react-merge-refs'
import { Icon } from '@/components'
import cn from 'classnames'

const InputNumber = memo(
  forwardRef((props: InputNumberProps, inputRef) => {
    const { id, value, onChange, placeholder = '', danger = false, disabled = false, max } = props
    const ref = useRef<HTMLInputElement>(null)

    const _onMinusClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
      const nextValue = value - 1 >= 0 ? value - 1 : 0
      onChange(nextValue)
    }, [onChange, value])

    const _onPlusClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
      const nextValue = max ? (value + 1 <= max ? value + 1 : max) : value + 1
      onChange(nextValue)
    }, [onChange, value])

    const _onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      e => {
        const value = +e.target.value
        const nextValue = max ? (value <= max ? value : max) : value
        onChange(nextValue)
      },
      [onChange],
    )

    return (
      <div className={cn(styles.input, styles.number_wrap, { [styles.danger]: danger })}>
        <button
          type="button"
          className={cn(styles.number_btn, styles.minus)}
          onClick={_onMinusClick}
          disabled={disabled}
        >
          <Icon name="minus" />
        </button>

        <button
          type="button"
          className={cn(styles.number_btn, styles.plus)}
          onClick={_onPlusClick}
          disabled={disabled}
        >
          <Icon name="plus" />
        </button>

        <input
          id={id}
          ref={mergeRefs([ref, inputRef])}
          value={value}
          onChange={_onChange}
          placeholder={placeholder}
          className={styles.number_input}
          type="number"
          inputMode="numeric"
        />
      </div>
    )
  }),
)

export default InputNumber
