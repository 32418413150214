import { changeSearchField, getSearchForm, TypeOfDoors } from '@/store/slices/registrationSlice'
import { useSelector, useDispatch } from 'react-redux'
import { Title, Text, CheckBox } from '@/components'
import styles from '../SearchPage.module.scss'

const filterName = 'types_of_doors'
const checkBoxes: { name: TypeOfDoors; title: string }[] = [
  { name: 'interior', title: 'Interior' },
  { name: 'exterior', title: 'Exterior' },
]

export default function StepOne() {
  const dispatch = useDispatch()

  const { data } = useSelector(getSearchForm)
  const value = data[filterName]

  const onFieldChange = (name: TypeOfDoors) => (checked: boolean) => {
    let newValue = checked ? [...value, name] : value.filter(item => item !== name)
    if (newValue.length === 0) newValue = name === 'exterior' ? ['interior'] : ['exterior']

    dispatch(changeSearchField({ fieldName: filterName, fieldValue: newValue }))
  }

  return (
    <div className={styles.content}>
      <Title size="medium" align="center">
        What <Text mark>type of doors</Text> would you like installed?
      </Title>

      <div className={styles.options}>
        {checkBoxes.map(item => {
          const { name, title } = item

          return (
            <CheckBox
              key={name}
              name={`${filterName}-${name}`}
              checked={value.includes(name)}
              title={title}
              onChange={onFieldChange(name)}
            />
          )
        })}
      </div>
    </div>
  )
}
