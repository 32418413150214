import { Project as ProjectProps } from './interfaces'
import styles from './Project.module.scss'
import { Picture } from '@/components'

export default function Project({ name, description, preview, media }: ProjectProps) {
  const count = media.length

  return (
    <div className={styles.project}>
      <button className={styles.action} />
      <div className={styles.header}>
        <div className={styles.img}>
          <Picture {...preview[0]} />
        </div>
        <span className={styles.count}>{count}</span>
      </div>
      <div className={styles.footer}>
        <div className={styles.name}>{name}</div>
        <div className={styles.descr}>{description}</div>
      </div>
    </div>
  )
}
