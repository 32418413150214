import { ActiveLink, Container, Icon } from '@/components'
import getLinkHref from '@/helpers/getLinkHref'
import { HeaderProps } from './interfaces'
import styles from './Header.module.scss'
import cn from 'classnames'

export default function Header({ type = 'default', children }: HeaderProps) {
  return (
    <header
      className={cn({
        [styles.header_default]: type === 'default',
        [styles.header_home]: type === 'home',
        [styles.header_half]: type === 'half',
      })}
    >
      <div className={styles.bar}>
        <Container size={type === 'home' ? 'wide' : type === 'half' ? 'halved' : undefined}>
          <div className={styles.row}>
            <ActiveLink href="/" activeClass={styles.active}>
              <a className={styles.logo}>Doors Install</a>
            </ActiveLink>

            <nav className={styles.nav}>
              <ActiveLink href={getLinkHref({ type: 'projects' })} activeClass={styles.active}>
                <a className={styles.nav_link}>Our projects</a>
              </ActiveLink>

              {type !== 'half' && (
                <ActiveLink
                  href={getLinkHref({ type: 'registration' })}
                  activeClass={styles.active}
                >
                  <a className={styles.nav_link}>
                    <span className={styles.nav_icon}>
                      <Icon name="drill" />
                    </span>
                    Installer sign up
                  </a>
                </ActiveLink>
              )}
            </nav>
          </div>
        </Container>
      </div>

      {children}
    </header>
  )
}
