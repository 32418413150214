import { getClosestState, findClosestMastersByZip } from '@/store/slices/registrationSlice'
import { useState, useCallback, Fragment, ChangeEventHandler } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { useSelector, useDispatch } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { ClosestByZipProps } from './interfaces'
import styles from './ClosestByZip.module.scss'
import { Button, Icon } from '@/components'
import Router from 'next/router'
import cn from 'classnames'

export default function ClosestByZip({ btnColor, className }: ClosestByZipProps) {
  const [value, setValue] = useState('')
  const [errorVisible, setErrorVisible] = useState(false)
  const [errorInvalid, setErrorInvalid] = useState(false)
  const [errorTips, setErrorTips] = useState<string[]>()
  const [result, setResult] = useState<'error' | 'success' | null>(null)

  const { loading: loadingZips } = useSelector(getClosestState)
  const loading = loadingZips.includes(value)

  const dispatch = useDispatch()

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      setValue(e.target.value.trim())
      setResult(null)
      if (errorVisible) setErrorVisible(false)
      if (errorInvalid) setErrorInvalid(false)
    },
    [errorVisible],
  )

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      if (value.length < 5) {
        setErrorInvalid(true)
        return
      }

      dispatch(
        findClosestMastersByZip({
          zip: value,
          onSuccess: () => {
            setResult('success')
            setTimeout(() => {
              Router.push(`/search/step-1?code=${value}`)
            }, 300)
          },
          onError: tips => {
            setErrorTips(tips)
            setErrorVisible(true)
            setResult('error')
          },
        }),
      )
    },
    [value],
  )

  const onErrorClose = useCallback(() => {
    setErrorVisible(false)
    setErrorInvalid(false)
  }, [])

  return (
    <form
      onSubmit={onSubmit}
      className={cn(styles.wrapper, {
        ...(className && { [className]: true }),
      })}
    >
      <div className={styles.field}>
        <input
          className={styles.input}
          placeholder="ZIP code"
          value={value}
          type="text"
          inputMode="numeric"
          onChange={onChange}
        />

        <div
          className={cn(styles.result, {
            [styles.success]: result === 'success',
            [styles.error]: result === 'error',
            [styles.visible]: result !== null,
          })}
        >
          {result === 'success' && <Icon name="success" />}
          {result === 'error' && <Icon name="close" />}
        </div>
      </div>

      <Button variant="primary" size="large" color={btnColor} disabled={loading} type="submit">
        Get Started
      </Button>

      <CSSTransition
        in={errorVisible || errorInvalid}
        timeout={{
          appear: 0,
          enter: 200,
          exit: 200,
        }}
        classNames={{
          enter: styles['on-enter'],
          enterActive: styles['on-enter-active'],
          exit: styles['on-exit'],
          exitActive: styles['on-exit-active'],
        }}
        unmountOnExit
        onEntered={(node: HTMLElement) => {
          const parent = node.offsetParent as HTMLElement
          const messageOffset = node.offsetHeight + 100
          if (parent.getBoundingClientRect().top <= messageOffset) {
            scroll.scrollTo(parent.offsetTop - messageOffset, { duration: 200, smooth: true })
          }
        }}
      >
        <div className={styles.message}>
          <button type="button" className={styles.close} onClick={onErrorClose}>
            <Icon name="close" />
          </button>
          <Icon name="distance" />
          {errorInvalid ? (
            <div>
              <p>Invalid ZIP code.</p>
              <p>Please, check the entered data.</p>
            </div>
          ) : (
            <div>
              <p>
                We are very sorry, but currently we have no recommended Installers at this area.
                Please, search for other area.
              </p>
              {errorTips && (
                <div className={styles.tips}>
                  <p>Try the following ZIP's:</p>
                  <p>
                    {errorTips.map((tip, ind) => (
                      <Fragment key={tip}>
                        <button
                          type="button"
                          onClick={() => {
                            setValue(tip)
                            setErrorVisible(false)
                            setResult(null)
                          }}
                        >
                          {tip}
                        </button>
                        {ind !== errorTips.length - 1 && ', '}
                      </Fragment>
                    ))}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </CSSTransition>
    </form>
  )
}
