import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '20',
  height = '24',
  viewBox = '0 0 20 24',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.976 10H13a3 3 0 0 1-3-3V.024L9.936.02A6.02 6.02 0 0 0 9.515 0H5a5.006 5.006 0 0 0-5 5v14a5.006 5.006 0 0 0 5 5h10a5.006 5.006 0 0 0 5-5v-8.515c0-.141-.01-.281-.02-.421L19.976 10ZM13 8.001h6.54a6.953 6.953 0 0 0-1.591-2.464l-3.485-3.486A6.976 6.976 0 0 0 12 .461v6.54a1 1 0 0 0 1 1Zm-7.245 6.071H3.81V19h1.253v-1.526h.721c1.1 0 1.8-.728 1.8-1.701 0-.98-.666-1.701-1.828-1.701Zm-.112 1.064c.427 0 .672.259.672.637 0 .364-.259.637-.672.637h-.58v-1.274h.58Zm4.464-1.064H8.28V19h1.827c1.589 0 2.464-.938 2.464-2.464s-.875-2.464-2.464-2.464Zm-.091 3.843h-.483v-2.758h.483c.847 0 1.281.518 1.281 1.379s-.434 1.379-1.281 1.379Zm4.548-.756V19h-1.253v-4.928h3.36v1.113h-2.107v.91h1.946v1.064h-1.946Z"
    />
  </svg>
)

export default SVG
