import React, { useContext, useState, createContext } from 'react'
import { nanoid } from 'nanoid'

export interface ContextState {
  list: Notification[]
  add: (config: Config) => void
  close: (id: string) => void
}

const NotificationContext = createContext({} as ContextState)

type NotificationProviderProps = {
  children: React.ReactNode
}

export function NotificationProvider({ children }: NotificationProviderProps) {
  const [notifications, setNotifications] = useState<Notification[]>([])

  const add = (config: Config) => {
    const notification = getNotificationConfig(config)

    if (notification.duration) {
      setTimeout(() => {
        close(notification.id)
      }, notification.duration)
    }

    setNotifications(prev => [...prev, notification])
  }

  const close = (id: string) => {
    setNotifications(prev =>
      prev.filter(item => {
        if (item.id === id) {
          if (item.onClose) item.onClose()
          return false
        }
        return true
      }),
    )
  }

  return (
    <NotificationContext.Provider value={{ list: notifications, add, close }}>
      {children}
    </NotificationContext.Provider>
  )
}

export function useNotification() {
  return useContext(NotificationContext)
}

export interface Notification {
  id: string
  content: React.ReactNode
  style: 'error'
  duration?: number
  onClose?: () => void
}

export interface Config {
  id?: string
  content: React.ReactNode
  style?: 'error'
  duration?: number
  onClose?: () => void
}

function getNotificationConfig(config: Config) {
  return {
    content: config.content,
    id: config.id || nanoid(),
    onClose: config.onClose,
    style: config.style || 'error',
    duration: config.duration,
  }
}
