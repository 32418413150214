import styles from './Burger.module.scss'
import cn from 'classnames'

type BurgerProps = {
  open: boolean
  onClick: (e: any) => void
  dark?: boolean
}

export default function Burger({ open, onClick, dark = false }: BurgerProps) {
  return (
    <div
      className={cn({
        [styles.wrapper]: true,
        [styles.open]: open,
        [styles.dark]: dark,
      })}
      onClick={onClick}
    >
      <div className={styles.inner} />
    </div>
  )
}
