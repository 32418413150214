import { Advantage as AdvantageProps } from './interfaces'
import styles from './Advantage.module.scss'
import { Icon } from '@/components'
import cn from 'classnames'

export default function Advantage({ title, description, icon, className }: AdvantageProps) {
  return (
    <div className={cn(styles.advantage, className)}>
      <div className={styles.icon}>
        <Icon name={icon} />
      </div>
      <div>
        <strong className={styles.title}>{title}</strong>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  )
}
