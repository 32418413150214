import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '17',
  height = '19',
  viewBox = '0 0 17 19',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.35 0a5.015 5.015 0 00-5.01 5.01 5.015 5.015 0 005.01 5.01 5.015 5.015 0 005.01-5.01A5.015 5.015 0 008.35 0zM14.583 13.292a7.142 7.142 0 00-5.12-2.16H7.236c-1.93 0-3.748.768-5.12 2.16A7.301 7.301 0 000 18.443c0 .308.25.557.557.557h15.586c.307 0 .556-.25.556-.557a7.3 7.3 0 00-2.116-5.15z" />
  </svg>
)

export default SVG
