import styles from './Steps.module.scss'
import cn from 'classnames'

export default function Steps() {
  return (
    <div className={styles.steps}>
      <div className={styles.step}>
        <Content title="ZIP code" desc="enter in the field">
          01
        </Content>
      </div>
      <div className={styles.line} />
      <div className={cn(styles.step, styles.end, styles.two)}>
        <Content title="Explore" desc="the list of the installers">
          02
        </Content>
      </div>
      <div className={cn(styles.line, styles.asc)} />
      <div className={cn(styles.step, styles.three)}>
        <Content title="Connect" desc="with installers">
          03
        </Content>
      </div>
      <div className={styles.line} />
      <div className={cn(styles.step, styles.end, styles.four)}>
        <Content title="Compare" desc="and hire">
          04
        </Content>
      </div>
    </div>
  )
}

type ContentProps = {
  title: string
  desc: string
  children: React.ReactNode
}

function Content({ title, desc, children }: ContentProps) {
  return (
    <div className={styles.number}>
      {children}
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{desc}</div>
      </div>
    </div>
  )
}
