import {
  getSearchForm,
  changeSearchField,
  SearchFields,
  ErrorType,
  sendRequestToMasters,
} from '@/store/slices/registrationSlice'
import { useState, useEffect, useMemo, useCallback, useRef, createRef } from 'react'
import { Button, Form, FormField, Input, MaskedPhoneInput } from '@/components'
import { useSelector, useDispatch } from 'react-redux'
import findScrollContainer from '@/helpers/findScrollContainer'
import findPos from '@/helpers/findPos'
import YMTraker from '@/metrics/YM'
import GTraker from '@/metrics/Gtag'

export default function SearchForm({ code }: { code: string }) {
  const dispatch = useDispatch()
  const { data, loading, errors: formErrors } = useSelector(getSearchForm)

  const refs = useRef(
    Object.keys(data).reduce((acc, key) => {
      acc[key] = createRef<HTMLInputElement>()
      return acc
    }, {} as RefsObject),
  )

  const [errors, setErrors] = useState<ErrorType<SearchFields> | null>(formErrors)

  useEffect(() => {
    setErrors(formErrors)

    const errorKeys = Object.keys(formErrors || {})

    if (errorKeys.length > 0) {
      let firstFieldWithError = Object.keys(data).find(key =>
        errorKeys.some(errorKey => errorKey === key),
      )

      if (firstFieldWithError) {
        const field = refs.current[firstFieldWithError].current
        if (field) {
          const scrollContainer = findScrollContainer(field)
          const positionY = findPos(field) - 120
          if (scrollContainer) scrollContainer.scrollTo(0, positionY)

          field.focus()
        }
      }
    }
  }, [formErrors])

  const onFieldChange = useCallback(
    (name: keyof SearchFields) => (value: any) => {
      dispatch(changeSearchField({ fieldName: name, fieldValue: value }))

      setErrors(prev => {
        if (!prev) return prev

        const errors = { ...prev }
        delete errors[name]
        return Object.keys(errors).length > 0 ? errors : null
      })
    },
    [],
  )

  const changeFunctions = useMemo(() => getOnChangeFunctions(data, onFieldChange), [])

  const onFormSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    dispatch(
      sendRequestToMasters({
        code,
        onSuccess: () => {
          YMTraker.request()
          GTraker.request()
        },
      }),
    )
  }

  return (
    <Form onSubmit={onFormSubmit}>
      <Form.Row>
        <FormField
          label="First Name"
          labelFor="installer_first_name"
          error={errors?.first_name?.[0]}
        >
          <Input
            id="installer_first_name"
            ref={refs.current.first_name}
            value={data.first_name}
            onChange={changeFunctions['first_name']}
            danger={!!errors?.first_name}
          />
        </FormField>
      </Form.Row>

      <Form.Row>
        <FormField label="Last Name" labelFor="installer_last_name" error={errors?.last_name?.[0]}>
          <Input
            id="installer_last_name"
            ref={refs.current.last_name}
            value={data.last_name}
            onChange={changeFunctions['last_name']}
            danger={!!errors?.last_name}
          />
        </FormField>
      </Form.Row>

      <Form.Row>
        <FormField label="Phone" labelFor="installer_phone" error={errors?.phone?.[0]}>
          <MaskedPhoneInput
            id="installer_phone"
            ref={refs.current.phone}
            value={data.phone}
            onChange={changeFunctions['phone']}
            danger={!!errors?.phone}
          />
        </FormField>
      </Form.Row>

      <Form.Row>
        <FormField optional label="Email" labelFor="installer_email" error={errors?.email?.[0]}>
          <Input
            id="installer_email"
            ref={refs.current.email}
            value={data.email}
            inputMode="email"
            onChange={changeFunctions['email']}
            danger={!!errors?.email}
          />
        </FormField>
      </Form.Row>

      <Form.Footer>
        <Button size="large" color="decor" variant="primary" disabled={loading}>
          Send Request
        </Button>
      </Form.Footer>
    </Form>
  )
}

type RefsObject = {
  [key: string]: React.RefObject<HTMLInputElement>
}

type OnChangeFunctionsCb = (name: keyof SearchFields) => (value: any) => void
type OnChangeFunctionsResult = {
  [key in keyof SearchFields]: (value: any) => void
}

function getOnChangeFunctions(
  fields: SearchFields,
  fn: OnChangeFunctionsCb,
): OnChangeFunctionsResult {
  const items = Object.keys(fields) as Array<keyof SearchFields>
  return items.reduce<OnChangeFunctionsResult>((acc, key) => {
    acc[key] = fn(key)
    return acc
  }, {} as OnChangeFunctionsResult)
}
