import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '34',
  height = '49',
  viewBox = '0 0 34 49',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M29.917 0H4.087A4.09 4.09 0 000 4.077v40.41a4.076 4.076 0 004.077 4.076h25.84a4.076 4.076 0 004.077-4.077V4.077A4.076 4.076 0 0029.917 0zm2.462 44.486a2.462 2.462 0 01-2.462 2.462H4.087a2.462 2.462 0 01-2.462-2.462V4.077a2.462 2.462 0 012.462-2.461h25.84a2.462 2.462 0 012.461 2.461v40.41h-.01z" />
    <path d="M6.481 26.714h21.041v1.616H6.482v-1.616zM6.481 32.379h21.041v1.616H6.482v-1.616zM6.481 38.85h21.041v1.616H6.482v-1.615zM17.002 22.39a8.011 8.011 0 100-16.022 8.011 8.011 0 000 16.023z" />
    <path
      d="M21.478 13.334a.481.481 0 00-.409-.333l-2.595-.237-1.026-2.405a.474.474 0 00-.437-.294.474.474 0 00-.437.294l-1.027 2.405-2.594.237a.479.479 0 00-.266.836l1.958 1.72-.58 2.547c-.038.19.028.38.19.495.085.057.18.095.276.095a.46.46 0 00.247-.067l2.242-1.34 2.234 1.34c.161.095.37.086.522-.019a.495.495 0 00.19-.494l-.58-2.547 1.959-1.72a.515.515 0 00.133-.513z"
      fill="#fff"
    />
  </svg>
)

export default SVG
