import styles from './Title.module.scss'
import cn from 'classnames'

type TitleProps = {
  level?: 1 | 2 | 3 | 4 | 5 | 6
  tag?: 'span'
  size?: 'large' | 'medium'
  align?: 'left' | 'center'
  children: React.ReactNode
}

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export default function Title({
  level = 1,
  tag,
  size = 'large',
  align = 'left',
  children,
}: TitleProps) {
  const headingTag = `h${level}` as HeadingTag
  const Component = tag ? tag : level ? headingTag : 'h1'

  return (
    <Component
      className={cn(styles.title, {
        [styles.medium]: size === 'medium',
        [styles.center]: align === 'center',
      })}
    >
      {children}
    </Component>
  )
}
