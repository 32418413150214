export default function getLinkProps({ type }: Props) {
  let href = ''

  switch (type) {
    case 'registration':
      href = '/registration'
      break
    case 'projects':
      href = '/projects'
      break
    default:
      break
  }

  return href
}

interface Props {
  type: 'registration' | 'projects'
}
