import styles from './Collapse.module.scss'
import { CollapseProps } from './interfaces'
import { Collapse } from 'react-collapse'
import { useState } from 'react'
import cn from 'classnames'

const theme = { collapse: styles.inner, content: styles.content }

export default function CollapseComponent({
  title,
  children,
  disabled = false,
  initialOpen = false,
}: CollapseProps) {
  const [open, setOpen] = useState(initialOpen)

  const toggle = () => {
    if (!disabled) {
      setOpen(prev => !prev)
    }
  }

  return (
    <div className={cn(styles.collapse, { [styles.disabled]: disabled })}>
      <div
        onClick={toggle}
        className={cn(styles.toggle, {
          [styles.open]: open,
        })}
      >
        {title}
      </div>

      <Collapse isOpened={open} theme={theme}>
        <div>{children}</div>
      </Collapse>
    </div>
  )
}
