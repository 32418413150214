import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import { Project } from '@/components/Projects/Project/interfaces'
import { AppThunk, RootState } from '@/store'

const initialState: SliceState = {
  data: [],
  loading: false,
  hasError: false,
  previousLimit: 0,
}

const projects = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    requestProjects(state) {
      state.loading = true
    },
    successProjects(state, { payload }: PayloadAction<Project[]>) {
      state.loading = false
      state.hasError = false
      state.data = payload
    },
    failureProjects(state) {
      state.loading = false
      state.hasError = true
    },
    updateLimit(state, { payload }: PayloadAction<Limit>) {
      state.previousLimit = payload
    },
  },
})

export const fetchProjects = (limit?: number): AppThunk => async (dispatch, getState, { api }) => {
  try {
    dispatch(requestProjects())
    const { data, previousLimit } = getState().projects
    if (previousLimit === 'all') {
      dispatch(successProjects(data))
      return
    } else {
      if (limit && previousLimit >= limit) {
        dispatch(successProjects(data))
        return
      }
      const nextLimit = !limit ? 'all' : limit > previousLimit ? limit : previousLimit
      dispatch(updateLimit(nextLimit))
    }

    const params = { limit }
    const response: FetchProjectsResponse = await api.projects({ params })

    if (response.data.length > 0) {
      dispatch(successProjects(response.data))
    } else {
      dispatch(failureProjects())
    }
  } catch (err) {
    dispatch(failureProjects())
  }
}

export const getProjects = createSelector(
  (state: RootState) => state.projects,
  projects => projects,
)

export const { requestProjects, successProjects, failureProjects, updateLimit } = projects.actions

export default projects.reducer

// <-- Types --> //

type SliceState = {
  data: Project[]
  loading: boolean
  hasError: boolean
  previousLimit: Limit
}

type Limit = number | 'all'

type FetchProjectsResponse = {
  result: 'success'
  data: Project[]
}
