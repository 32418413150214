import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '18',
  height = '17',
  viewBox = '0 0 18 17',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.05.927c.298-.921 1.602-.921 1.901 0l1.294 3.983a1 1 0 0 0 .951.691h4.188c.969 0 1.372 1.24.588 1.81l-3.388 2.46a1 1 0 0 0-.363 1.119l1.294 3.982c.299.922-.755 1.688-1.54 1.118L9.589 13.63a1 1 0 0 0-1.176 0L5.024 16.09c-.783.57-1.838-.196-1.538-1.118L4.78 10.99a1 1 0 0 0-.364-1.118L1.028 7.41c-.783-.57-.38-1.809.588-1.809h4.188a1 1 0 0 0 .951-.69L8.049.926Z" />
  </svg>
)

export default SVG
