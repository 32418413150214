import { IconProps } from './interfaces'

const SVG = ({ fill = 'none', width = '77', height = '47', viewBox = '0 0 77 47' }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M36.168 1.309H1.27v44.264h34.898V1.309z" fill="#D6E0EA" />
    <path d="M57.94 10.948H36.166v34.623h21.772V10.948z" fill="#B8C4CC" />
    <path d="M75.67 16.733H57.942v28.84h17.73v-28.84z" fill="#929CA5" />
    <path
      d="M75.7 15.423H59.27v-4.484a1.3 1.3 0 00-1.3-1.3H37.499V1.3A1.3 1.3 0 0036.198 0H1.3A1.3 1.3 0 000 1.3v44.263a1.3 1.3 0 001.3 1.3h74.4a1.3 1.3 0 001.3-1.3v-28.84a1.3 1.3 0 00-1.3-1.3zM2.6 2.6h32.298v41.663H2.6V2.6zm34.898 39.188h2.47v-2.6h-2.47V36.96h2.47v-2.6h-2.47v-2.228h2.47v-2.6h-2.47v-2.227h2.47v-2.6h-2.47v-2.228h2.47v-2.6h-2.47v-2.228h2.47v-2.6h-2.47v-2.81H56.67v32.025H37.498v-2.476zM74.4 44.264H59.27V18.022H74.4v26.242z"
      fill="#000"
    />
    <path
      d="M46.793 15.049h-4.259v2.6h4.259v-2.6zM53.618 15.049h-4.259v2.6h4.259v-2.6zM46.793 19.877h-4.259v2.6h4.259v-2.6zM53.618 19.877h-4.259v2.6h4.259v-2.6zM46.793 24.705h-4.259v2.6h4.259v-2.6zM53.618 24.705h-4.259v2.6h4.259v-2.6zM46.793 29.532h-4.259v2.6h4.259v-2.6zM53.618 29.532h-4.259v2.6h4.259v-2.6zM46.793 34.36h-4.259v2.6h4.259v-2.6zM53.618 34.36h-4.259v2.6h4.259v-2.6zM46.793 39.188h-4.259v2.6h4.259v-2.6zM53.618 39.188h-4.259v2.6h4.259v-2.6zM65.787 20.173H61.53v2.6h4.258v-2.6zM72.612 20.173h-4.258v2.6h4.258v-2.6zM65.787 25.002H61.53v2.6h4.258v-2.6zM72.612 25.002h-4.258v2.6h4.258v-2.6zM65.787 29.83H61.53v2.6h4.258v-2.6zM72.612 29.83h-4.258v2.6h4.258v-2.6zM65.787 34.658H61.53v2.6h4.258v-2.6zM72.612 34.658h-4.258v2.6h4.258v-2.6zM65.787 39.485H61.53v2.6h4.258v-2.6zM72.612 39.485h-4.258v2.6h4.258v-2.6zM10.17 5.394H5.666v2.6h4.506v-2.6zM17.391 5.394h-4.506v2.6h4.506v-2.6zM24.613 5.394h-4.506v2.6h4.506v-2.6zM31.833 5.394h-4.506v2.6h4.506v-2.6zM10.17 10.222H5.666v2.6h4.506v-2.6zM17.391 10.222h-4.506v2.6h4.506v-2.6zM24.613 10.222h-4.506v2.6h4.506v-2.6zM31.833 10.222h-4.506v2.6h4.506v-2.6zM10.17 15.049H5.666v2.6h4.506v-2.6zM17.391 15.049h-4.506v2.6h4.506v-2.6zM24.613 15.049h-4.506v2.6h4.506v-2.6zM31.833 15.049h-4.506v2.6h4.506v-2.6zM10.17 19.877H5.666v2.6h4.506v-2.6zM17.391 19.877h-4.506v2.6h4.506v-2.6zM24.613 19.877h-4.506v2.6h4.506v-2.6zM31.833 19.877h-4.506v2.6h4.506v-2.6zM10.17 24.705H5.666v2.6h4.506v-2.6zM17.391 24.705h-4.506v2.6h4.506v-2.6zM24.613 24.705h-4.506v2.6h4.506v-2.6zM31.833 24.705h-4.506v2.6h4.506v-2.6zM10.17 29.532H5.666v2.6h4.506v-2.6zM17.391 29.532h-4.506v2.6h4.506v-2.6zM24.613 29.532h-4.506v2.6h4.506v-2.6zM31.833 29.532h-4.506v2.6h4.506v-2.6zM10.17 34.36H5.666v2.6h4.506v-2.6zM17.391 34.36h-4.506v2.6h4.506v-2.6zM24.613 34.36h-4.506v2.6h4.506v-2.6zM31.833 34.36h-4.506v2.6h4.506v-2.6zM10.17 39.188H5.666v2.6h4.506v-2.6zM17.391 39.188h-4.506v2.6h4.506v-2.6zM24.613 39.188h-4.506v2.6h4.506v-2.6zM31.833 39.188h-4.506v2.6h4.506v-2.6z"
      fill="#000"
    />
  </svg>
)

export default SVG
