import { ReactNode } from 'react'

export type LayoutProps = LayoutPropsDefaultType | LayoutPropsHalvedType

export function isDefaultLayout(
  props: LayoutPropsDefaultType | LayoutPropsHalvedType,
): props is LayoutPropsDefaultType {
  return !props.type || props.type === 'default'
}

export interface LayoutPropsDefaultType extends LayoutDefaultProps {
  type?: 'default'
}

export interface LayoutPropsHalvedType {
  type: 'halved'
  theme?: 'default' | 'dark'
  children: LayoutHalvedChildren
}

export interface LayoutDefaultProps {
  theme?: 'default' | 'dark'
  noMobileFooter?: boolean
  children: LayoutDefaultChildren
}

export type LayoutDefaultChildrenSlots = {
  mainContent: ReactNode
  headerContent?: ReactNode
  footerContent?: ReactNode
}

export type LayoutDefaultChildren = ReactNode | LayoutDefaultChildrenSlots

export interface LayoutHalvedProps {
  children: LayoutHalvedChildren
}

export type LayoutHalvedChildren = {
  mainContent: ReactNode
  sideContent: ReactNode
}
