import { CSSTransition } from 'react-transition-group'
import { DrawerProps } from './interfaces'
import styles from './Drawer.module.scss'
import { Portal, Icon } from '@/components'
import { useEffect, useRef } from 'react'
import noScroll from 'no-scroll'
import cn from 'classnames'

export default function Drawer({ children, visible = false, title, onClose }: DrawerProps) {
  useEffect(() => {
    if (visible) {
      noScroll.on()
    } else {
      noScroll.off()
    }
  }, [visible])
  const ref = useRef<HTMLDivElement | null>(null)

  const close = () => {
    noScroll.off()
    onClose()
  }

  return (
    <CSSTransition
      in={visible}
      timeout={{
        appear: 0,
        enter: 100,
        exit: 100,
      }}
      classNames={{
        enter: styles['on-enter'],
        enterActive: styles['on-enter-active'],
        enterDone: styles['on-enter-done'],
        exit: styles['on-exit'],
        exitActive: styles['on-exit-active'],
      }}
      unmountOnExit
    >
      <Portal>
        <div ref={ref} onClick={close} className={styles.drawer}>
          <div
            className={cn(styles.container, {
              [styles.visible]: visible,
              [styles.no_title]: !title,
            })}
          >
            <button type="button" className={styles.close} onClick={close}>
              <Icon name="close-big" />
            </button>
            <div
              className={styles.dialog}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              {title && <div className={styles.title}>{title}</div>}
              {children}
            </div>
          </div>
        </div>
      </Portal>
    </CSSTransition>
  )
}
