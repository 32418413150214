import { useCallback, useMemo, useState } from 'react'
import { Icon, Tooltip } from '@/components'
import styles from './Rating.module.scss'
import cn from 'classnames'

interface RatingProps {
  value: number
  tip: string
}

const POPPER_OPTIONS = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [-34, 16],
      },
    },
  ],
}

const STARS = 5

export default function Rating({ value, tip }: RatingProps) {
  const [instance, setInstance] = useState<any>()

  const tooltipContent = useMemo(
    () => (
      <div className={styles.tip}>
        <div>{tip}</div>
        <button
          className={styles.tipClose}
          onClick={() => {
            if (instance) {
              instance.hide()
            }
          }}
        >
          <Icon name="close" />
        </button>
      </div>
    ),
    [instance],
  )

  const onTooltipCreate = useCallback(ins => setInstance(ins), [])

  return (
    <div className={styles.rating}>
      <ul className={styles.stars}>
        {new Array(STARS).fill(null).map((_i, idx) => (
          <li key={idx} className={cn(styles.star, { [styles.checked]: idx < value })}>
            <Icon name="star" />
          </li>
        ))}
      </ul>

      <Tooltip
        interactive
        trigger="mouseenter"
        placement="top-start"
        content={tooltipContent}
        onCreate={onTooltipCreate}
        popperOptions={POPPER_OPTIONS}
      >
        <span className={styles.tipTrigger}>
          <Icon name="question" />
        </span>
      </Tooltip>
    </div>
  )
}
