import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '43',
  height = '48',
  viewBox = '0 0 43 48',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.245 35.743a3.688 3.688 0 002.452-3.46 3.67 3.67 0 00-3.669-3.668 2.036 2.036 0 01-2.033-2.034c0-1.12.912-2.033 2.033-2.033.884 0 1.673.589 1.94 1.425h1.681a3.692 3.692 0 00-2.395-2.85l-.399-.143v-2.918h-1.634v2.918l-.4.142a3.688 3.688 0 00-2.451 3.46 3.67 3.67 0 003.668 3.668c1.121 0 2.034.912 2.034 2.034a2.036 2.036 0 01-2.034 2.033c-.884 0-1.673-.589-1.939-1.425h-1.682a3.692 3.692 0 002.395 2.85l.4.144v2.917h1.634v-2.917l.399-.143z" />
    <path d="M33.586 41.122c-1.322 2.775-4.134 4.695-7.385 4.695H9.827c-4.515 0-8.192-3.716-8.192-8.288a8.19 8.19 0 011.074-4.048l9.95-19.995.17-.343h10.379l.17.342 9.95 19.996c.524.922.866 1.939.999 2.984h1.653a9.934 9.934 0 00-1.216-3.782l-.01-.01v-.01l-.01-.009-8.761-17.62-.742-1.482-.19-.39-.095-.2.2-.085.399-.161a6.621 6.621 0 001.511-.865c1.445-1.112 3.174-3.43 3.174-8.183V.01h-3.668a6.48 6.48 0 00-4.951 2.28l-.295.352-.152.171-.152-.171-.304-.333C19.596 1.083 18.503.41 18.456.38l-.437-.266-.428.257c-.047.028-1.14.703-2.262 1.929l-.304.332-.152.172-.152-.172-.295-.351A6.512 6.512 0 009.475 0H5.797v3.678c0 4.562 1.606 6.87 2.946 8.002l.551.466.276.228-5.493 1.834.523 1.558 4.723-1.577.922-.304.466-.152-.656 1.311-8.763 17.61-.009.01v.01l-.01.009A9.82 9.82 0 000 37.529c0 5.474 4.41 9.922 9.827 9.922H26.21c4.162 0 7.736-2.632 9.161-6.33h-1.786zM7.432 3.678V1.644h2.034c1.739 0 3.354.931 4.22 2.424l.104.19.057.095-.143.285a7.573 7.573 0 00-.76 2.708h1.653c.267-2.366 2.006-4.134 3.042-4.98l.256-.209.124-.104.123.104.257.21c1.036.845 2.775 2.594 3.041 4.98h1.644a7.57 7.57 0 00-.76-2.71l-.143-.284.057-.095.105-.19a4.88 4.88 0 014.22-2.424h2.033v2.034c0 3.146-.827 5.493-2.404 6.776-1.122.922-2.253 1.036-2.471 1.045H12.317c-.21 0-4.885-.285-4.885-7.821z" />
    <path d="M33.538 36.921l1.559 1.559 3.46-3.46 1.197 1.198-4.61 4.619-.057.028-2.746-2.746 1.197-1.198zm-4.305-5.93h-.627v6.757c0 2.29.789 4.533 2.214 6.32l.18.228.096.114.874.874.105.086.2.171c.96.798 2.052 1.397 3.24 1.796l.2.067.2-.067a10.254 10.254 0 005.026-3.659 9.929 9.929 0 001.958-5.93v-6.757h-.617c-1.635 0-3.127-.314-4.458-.931-1.017-.475-1.568-.96-1.663-1.055l-.428-.428-.437.437c-.01.01-1.501 1.426-4.362 1.854l-.218.028s-.238.029-.59.048c-.38.028-.893.047-.893.047z" />
  </svg>
)

export default SVG
