import { memo, forwardRef, useRef, useCallback } from 'react'
import { InputProps } from './interfaces'
import styles from './Input.module.scss'
import InputMask from 'react-input-mask'
import mergeRefs from 'react-merge-refs'
import cn from 'classnames'

const MaskedPhoneInput = memo(
  forwardRef((props: InputProps, inputRef) => {
    const {
      id,
      value,
      onChange,
      placeholder = '',
      danger = false,
      disabled = false,
      inputMode = 'tel',
    } = props

    const _onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
      e => {
        const value = e.target.value
        // leave only numbers and +
        const fixedValue = value.replace(/[^\d\+]+/g, '')
        onChange(fixedValue)
      },
      [onChange],
    )

    const ref = useRef<HTMLInputElement>(null)

    return (
      <InputMask
        id={id}
        mask="+1 (999) 999-99-99"
        type="text"
        inputMode={inputMode}
        value={value}
        onChange={_onChange}
        className={cn(styles.input, { [styles.danger]: danger })}
        placeholder={placeholder}
        disabled={disabled}
        inputRef={el => mergeRefs([ref, inputRef])(el)}
      />
    )
  }),
)

export default MaskedPhoneInput
