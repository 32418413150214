import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '21',
  height = '26',
  viewBox = '0 0 21 26',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.887.892l-3.56 1.66-.333-.711a.786.786 0 00-1.044-.38l-1.752.816-2.064-1.248a.785.785 0 00-.74-.044L.847 4.969a.786.786 0 00-.38 1.044l3.32 7.121a.786.786 0 001.045.38l1.353-.63-.623 9.41c.008.01.014.023.017.036a.833.833 0 00.02.155.456.456 0 00.037.19l1.328 2.85a.786.786 0 001.044.38l5.697-2.657a.786.786 0 00.38-1.044l-1.328-2.849a.786.786 0 00-1.044-.38l-.933.435.596-8.947 2.001-.933a.785.785 0 00.442-.596l.37-2.384 1.752-.817a.786.786 0 00.38-1.044l-.332-.712 3.56-1.66a.786.786 0 10-.663-1.425zM12.33 22.156l-4.273 1.992-.664-1.424 4.273-1.993.664 1.425zm-3.172-1.989l-1.937.903.596-8.947 1.93-.9-.59 8.944zm3.171-11.882l-7.448 3.473-2.657-5.697 7.449-3.473 1.86 1.127 1.13 2.42-.334 2.15zm2.237-3.644l-.712.332-.664-1.424.712-.332.664 1.424zm-3.892.948a.786.786 0 01-.38 1.044L6.02 8.626a.786.786 0 01-.664-1.424l4.272-1.993a.786.786 0 011.045.38z" />
  </svg>
)

export default SVG
