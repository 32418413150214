import { LayoutDefaultProps, LayoutDefaultChildrenSlots } from './interfaces'
import { isObject } from '@/helpers/isObject'
import { Header, Footer } from '@/components'
import styles from './Layout.module.scss'

export default function LayoutDefault({
  theme = 'default',
  noMobileFooter,
  children,
}: LayoutDefaultProps) {
  if (isNamedSlots(children)) {
    const { mainContent, headerContent, footerContent } = children

    return (
      <>
        <Header type={theme === 'dark' ? 'home' : 'default'}>{headerContent}</Header>
        <main className={styles.content}>{mainContent}</main>
        <Footer type={theme === 'dark' ? 'home' : 'default'} noMobileFooter={noMobileFooter}>
          {footerContent}
        </Footer>
      </>
    )
  }

  return (
    <>
      <Header type={theme === 'dark' ? 'home' : 'default'} />
      <main className={styles.content}>{children}</main>
      <Footer type={theme === 'dark' ? 'home' : 'default'} noMobileFooter={noMobileFooter} />
    </>
  )
}

const isNamedSlots = (children: any): children is LayoutDefaultChildrenSlots =>
  isObject(children) &&
  ('mainContent' in children || 'headerContent' in children || 'footerContent' in children)
